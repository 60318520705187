import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import { isEmpty, convertLbsToKg, convertKgToLbs, getThreeDaysAgo} from '../../js/common';
import DatePicker from "react-datepicker";
import DaySelector from './DaySelector';
import { getPatientLabAndDemographicData } from '../../js/accuro_utils';
import { useLoading } from '../../context/LoadingContext';
import { UserContext } from '../../context/UserContext';

function CalendarInput({ setPatientInfo, patientInfo, setCalendarKey, calendarMode, startDate, setStartDate, stimStartDay, setStimStartDay, adminMode}) {
  const x = patientInfo;

  const { setIsLoading } = useLoading(); 
  const { authToken } = useContext(UserContext);

  const [name, setName] = useState(patientInfo.name);
  const [amh, setAMH] = useState(patientInfo.amh);
  const [weight, setWeight] = useState(patientInfo.weight);
  const [takingAntibiotics, setPatientTakingAntibiotics] = useState(!isEmpty(patientInfo.takingAntibiotics) ?  patientInfo.takingAntibiotics : null);
  const [eltroxinInPretreatment, setEltroxinInPretreatment] = useState(!isEmpty(patientInfo.eltroxinInPretreatment) ?  patientInfo.eltroxinInPretreatment : null);
  const [stimType, setStimType] = useState(!isEmpty(patientInfo.stimType) ?  patientInfo.stimType : null);
  const [accuroId, setAccuroId] = useState(patientInfo.accuroId);
  const [existingPatientMedications, setExistingPatientMedications] = useState(!isEmpty(patientInfo.existingPatientMedications) ? patientInfo.existingPatientMedications : []);
  const [errors, setErrors] = useState({});
  const onSubmit = (e) => {
    e.preventDefault();
    setPatientInfo({ ...patientInfo, name, amh: !isEmpty(amh) ? Math.ceil(amh) : '', weight: weight, takingAntibiotics, accuroId, existingPatientMedications}); // NOTE: Rounding the AMH value we use up
  };

    // Fetch data from Accuro API
    const handleFetchAccuroData = (e) => {
      e.preventDefault();
      setIsLoading(true);
      getPatientLabAndDemographicData(accuroId, authToken)
        .then(data => {
          // setHeight(data.height || '');
          let amh = data?.labData?.amh || '';
          let weight = data?.labData?.weight || '';
          let name = data.demographics?.name;

          setAMH(amh);
          setWeight(weight);
          setName(name)
          setPatientInfo({ ...patientInfo, name, amh: !isEmpty(amh) ? Math.ceil(amh) : '', weight, takingAntibiotics, accuroId});
          setIsLoading(false);

        })
        .catch(error => {
          console.error('Error fetching data from Accuro API:', error);
          setErrors({ ...errors, accuroId: 'Failed to fetch data from Accuro API' });
          setIsLoading(false);

        });
    };
  

  // enforce max/min for manual entry
  // amh can be 0-100
  const handleAMHChange = (e) => {
    const value = e.target.value;
      if (value == '' || (value >= 0 && value <= 100)) { // allow value of '' when clearing input
        setAMH(value);
        setPatientInfo({ ...patientInfo, amh: !isEmpty(amh) ? Math.ceil(amh) : ''});
        setErrors({ ...errors, amh: '' });
      } else {
        setErrors({ ...errors, amh: 'AMH must be between 0 and 100' });
      }
  };

  const handleMedicationChange = (e) => {
    const { value, checked } = e.target;
    let updatedMedications;
    if (value === "None" && checked) {
      // Clear the list and only include "None"
      updatedMedications = ["None"];
    } else {
      // Remove "None" if any other medication is selected
      updatedMedications = checked
        ? [...existingPatientMedications.filter(med => med !== "None"), value]
        : existingPatientMedications.filter(med => med !== value);
    }
    setExistingPatientMedications(updatedMedications);
    setPatientInfo({ ...patientInfo, existingPatientMedications: updatedMedications });
  };
  
  
  const handleChangeStimStart = (dayOfWeek) => {
    let stimOffset;

    switch (dayOfWeek.value) {
      case 'Monday':
        stimOffset = 0;
        break;
      case 'Saturday':
        stimOffset = -2;
        break;
      case 'Sunday':
        stimOffset = -1;
        break;
      default:
        console.log('No day selected');
    }
    setStimStartDay({stimStartDayOfWeek: dayOfWeek.value, stimOffset, label:dayOfWeek.label, value:dayOfWeek.value});

  };



  // enforce max/min for manual entry
  // weight can be 0+  
  const handleWeightChange = (e) => {
    const value = Math.max(0, e.target.value);
    setWeight(value);
    setPatientInfo({ ...patientInfo, weight: value});

  };

  const onAntibioticSelect = (e) => {
    setPatientTakingAntibiotics(e.target.value === 'true');
}
  const onStimTypeChange = (e) => {
    setStimType(e.target.value );
  }

  return (
    <div className="patient-info-form">
      <Form>
        <Form.Group controlId="patientInfo">




          { calendarMode === 'clinical' && 
            <div className="accuro-fetch">
              <Form.Label>Enter Accuro ID:</Form.Label>
              <input
                value={accuroId}
                onChange={(e) => setAccuroId(e.target.value)}
                // required
              />
              &emsp;<button className="btn btn-secondary" onClick={handleFetchAccuroData}>Fetch Data</button>
              {!isEmpty(errors.accuroId) && <><br /><small className='form-error-text'>{errors.accuroId}</small></>}
            </div>
          }

          <div className="patient-info-row">
               {calendarMode === 'patient' ? <Form.Label>Enter your name:</Form.Label> : <Form.Label>Enter patient's name:</Form.Label>}
              <input 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                onBlur={onSubmit}
                // required 
              />
              {adminMode === true &&
                <>
                  <br/>
                  <Form.Label className='required'>Select Stim Start Day</Form.Label>
                  <div className='day-select-container'>
                    <DaySelector selectedDay={stimStartDay} onChange={handleChangeStimStart}/>
                  </div>
                  
                  <br/>
                  <Form.Label className='required'>Select Stim Type</Form.Label><br />
                  <div className='stim-type inline-radio'>
                        <Form.Check
                            checked={stimType==='EF'}
                            type="radio"
                            label="Egg Freezing"
                            value={'EF'}
                            name="stradioGroup" 
                            id="stradioOption2"
                            onChange={(e) => onStimTypeChange(e)}
                            onClick={() => setPatientInfo({ ...patientInfo, stimType: 'EF'})}

                        />
                        <Form.Check
                            checked={stimType==='IVF'}
                            type="radio"
                            label="IVF"
                            value={'IVF'}
                            name="stradioGroup" 
                            id="stradioOption1"
                            onChange={(e) => onStimTypeChange(e)}
                            onClick={() => setPatientInfo({ ...patientInfo, stimType: 'IVF'})}
                          />
                  </div>

                </>
              }


            { calendarMode === 'clinical' && 
              <>

                <div>
                  <Form.Label className='required'> Pre-treatment Start:</Form.Label>&nbsp;
                  <DatePicker 
                    placeholderText="Select Start Date"
                    selected={startDate} 
                    
                    value={startDate} 
                    onChange={(date) => setStartDate(date.setHours(0,0,0,0))} 
                    // minDate={getThreeDaysAgo() || null} // On 1-8-2023, we set 3 days ago to be the min date'
                    portalId="root"
                  />
                </div>
                <Form.Label className='required'>Enter AMH (pmol/L):</Form.Label>
                <input 
                  type="number" 
                  value={amh} 
                  onChange={handleAMHChange} 
                  onBlur={handleAMHChange}
                  min="0" 
                  max="100" 
                  required 
                />
                {errors.amh && <><br/><small className='form-error-text'>{errors.amh}</small></>}
                <br/>
                <Form.Label className='required'>Enter Weight (lbs):</Form.Label>
                <input 
                  type="number" 
                  value={weight} 
                  onChange={handleWeightChange} 
                  onBlur={handleWeightChange}
                  min="0" 
                  required 
                />
                <br/>
                
                <Form.Label className='required'>Select Stim Start Day</Form.Label>
                <div className='day-select-container'>
                  <DaySelector selectedDay={stimStartDay} onChange={handleChangeStimStart}/>
                </div>
                <br/>

                <Form.Label className='required'>Select Stim Type</Form.Label><br />
                  <div className='stim-type inline-radio'>
                        <Form.Check
                            checked={stimType==='EF'}
                            type="radio"
                            label="Egg Freezing"
                            value={'EF'}
                            name="stradioGroup" 
                            id="stradioOption2"
                            onChange={(e) => onStimTypeChange(e)}
                            onClick={() => setPatientInfo({ ...patientInfo, stimType: 'EF'})}

                        />

                        <Form.Check
                            checked={stimType==='IVF'}
                            type="radio"
                            label="IVF"
                            value={'IVF'}
                            name="stradioGroup" 
                            id="stradioOption1"
                            onChange={(e) => onStimTypeChange(e)}
                            onClick={() => setPatientInfo({ ...patientInfo, stimType: 'IVF'})}
                            />
                  </div>
                <Form.Label className='required'>Will patient be on the Amoxi-clav protocol (antibiotics)?</Form.Label><br />
                <div className='antibiotics inline-radio'>
                      <Form.Check
                          checked={takingAntibiotics===false}
                          type="radio"
                          label="No"
                          value={false}
                          name="aradioGroup" 
                          id="aradioOption2"
                          onChange={onAntibioticSelect}
                          onClick={() => setPatientInfo({ ...patientInfo, takingAntibiotics: false})}

                      />

                      <Form.Check
                          checked={takingAntibiotics===true}
                          type="radio"
                          label="Yes"
                          value={true}
                          name="aradioGroup" 
                          id="aradioOption1"
                          onChange={onAntibioticSelect}
                           onClick={() => setPatientInfo({ ...patientInfo, takingAntibiotics: true})}
                          
                          />
                  </div>

                {/* <Form.Label className='required'>Should patient start Eltroxin with Pretreatment?</Form.Label><br />
                <div className='eltroxin-pre inline-radio'>
                      <Form.Check
                          checked={eltroxinInPretreatment===false}
                          type="radio"
                          label="No"
                          value={false}
                          name="eltroxinradioGroup" 
                          id="eltroxinradioOption2"
                          onChange={()=>setEltroxinInPretreatment(false)}
                          onClick={() => setPatientInfo({ ...patientInfo, eltroxinInPretreatment: false})}

                      />

                      <Form.Check
                          checked={eltroxinInPretreatment===true}
                          type="radio"
                          label="Yes"
                          value={true}
                          name="eltroxinradioGroup" 
                          id="eltroxinradioOption1"
                          onChange={()=>setEltroxinInPretreatment(true)}
                           onClick={() => setPatientInfo({ ...patientInfo, eltroxinInPretreatment: true})}
                          
                          />
                  </div> */}


                  <div className='existing-meds-input-container'>
                    <Form.Label className='required'>Please note if patient is already taking any of the below medications:</Form.Label>
                    <div className="medication-options">
                      <Form.Check 
                        id='eltroxin-check'
                        type="checkbox" 
                        label="Eltroxin (or other thyroid medication)" 
                        value="Eltroxin" 
                        checked={existingPatientMedications.includes("Eltroxin")}
                        onChange={handleMedicationChange}
                      />
                      <Form.Check 
                        id='metformin-check' 
                        type="checkbox" 
                        label="Metformin" 
                        value="Metformin" 
                        checked={existingPatientMedications.includes("Metformin")}
                        onChange={handleMedicationChange}
                      />
                      <Form.Check 
                        id='none-check' 
                        type="checkbox" 
                        label="None of the above" 
                        value="None" 
                        checked={existingPatientMedications.includes("None")}
                        onChange={handleMedicationChange}
                      />
                    </div>
                  </div>



              </>
            }


          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

export default CalendarInput;
