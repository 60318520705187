import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
// import mixpanel from 'mixpanel-browser';

const UserContext = createContext();

// // Initialize Mixpanel
// mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
//   debug: true,
//   track_pageview: true,
//   persistence: 'localStorage'
// });

const validSuperadmins = [
  'kkhaled@vennahealth.com',
  'tech@vennahealth.com',
  'mwang@vennahealth.com',
  // 'jmorrison@vennahealth.com', //temporarily remove james so he can demo
  'aghazi@vennahealth.com',"Ahmad.Ghazi@hannamfertility.com",
  'marilyn.wang@vennahealth.com', 'Marilyn.Wang@hannamfertility.com',
  'emcmahon@vennahealth.com', 'Eileen.McMahon@hannamfertility.com',
];

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ email: 'mr.test@hannamfertility.com', name: 'Test Test', isSuperAdmin: window.location.hostname === "localhost" }); // localhost is always superadmin for testing
  console.log('what is my node env variable?',process.env.NODE_ENV )
  console.log('what is my app env variable?',process.env.REACT_APP_ENV )

  useEffect(() => {
    const fetchUserData = async () => {                   
      if (process.env.REACT_APP_CALENDAR_MODE === 'clinical'){

        try {
            const response = await axios.get(`${process.env.PUBLIC_URL}/.auth/me`, { withCredentials: true });
            const userData = response.data[0].user_claims.reduce((acc, claim) => {
                if (claim.typ === "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress") {
                    acc.email = claim.val;
                }
                if (claim.typ === 'name') {
                    acc.name = claim.val;
                }
                return acc;
            }, { email: '', name: '' });
            const isSuperAdmin = validSuperadmins.indexOf(userData.email) > -1;
            const authToken = response.data[0].id_token || response.data[0].access_token;
            setUser({...userData, 'isSuperAdmin': isSuperAdmin, authToken });

            // // Identify user in Mixpanel
            // mixpanel.identify(userData.email);

            // // Set user properties in Mixpanel
            // mixpanel.people.set({
            // '$email': userData.email,
            // '$name': userData.name,
            // });

        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
}

    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };