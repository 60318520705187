import React, { useState, useEffect, useContext   } from 'react';

import { Dna } from 'react-loader-spinner';
import FullCalendar from '@fullcalendar/react';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';

import moment from 'moment/moment';
import eventGenerator, { generateClinicalCalendarEvents } from '../../js/eventGenerator';
import ClinicalEventContent from './ClinicalEventContent';
import { getArrayIdFromObject, getWeeksBetweenDates, areDatesEqual } from '../../js/common';
import LoadingSpinner from '../common/loadingSpinner';
import { isEmpty } from '../../js/common';
import { useMediaQuery } from 'react-responsive';
import { clinicalExportToPDF } from '../../js/clinicalExportToPdf';
import getPergoverisDose from '../../js/temp_pergoveris';
import { useLoading } from '../../context/LoadingContext';
import { sendConsentEmail } from '../../js/api/vennabrain';
import { toast } from 'react-toastify';
import { UserContext } from '../../context/UserContext';
import AlertPopup from '../common/AlertPopup';



const ClinicalCalendar = ({events, medicationList, startDate, setStartDate, patientBlackouts = [], setShowEditorModal, setCalendarKey, calendarKey, innerRef, patientInfo, scrollToDescription, onFormSubmit, calendarConfig, setPrintView, printView}) => {
  const {name = 'Patient', amh, weight, takingAntibiotics, existingPatientMedications} = patientInfo;
  const {setIsLoading } = useLoading(); 
  const { email } = useContext(UserContext);

  useEffect(() => {
  if (!isEmpty(innerRef.current)) {
    setTimeout(() => innerRef.current.scrollIntoView({ behavior: 'smooth' }), 1)
  }  
}, [calendarKey]);

  useEffect(() => {
    const fetchMedications = async () => {


          
      console.log(medicationList)
      const calEvents = generateClinicalCalendarEvents(events, amh, weight, takingAntibiotics, medicationList, calendarConfig);
      setClinicalCalendarEvents(calEvents);
    }
    fetchMedications();

  }, [calendarKey]);

  const [isEditable] = useState(new URLSearchParams(window.location.search).get('clinician-mode') === '1');

  const printWidth = printView ? {width: 961} : undefined;
  const isDesktop = useMediaQuery({ query: `(min-width: 961px)` }, printWidth);

  const formattedStart = events[getArrayIdFromObject(events, 'id', "call-day-1")]['start']; // set get the first blackout event to indicate the start of the calendar
  const formattedEnd = events[getArrayIdFromObject(events, 'id', "phase-4-recovery")]['end']; // set final event's "end" to be the calendar's official end date


  const calendar1Start = events[getArrayIdFromObject(events, 'id', "phase-1-meds")]['start']; 
  const calendar1End = events[getArrayIdFromObject(events, 'id', "no-medications-2")]['end']; 
  const calendar2Start = events[getArrayIdFromObject(events, 'id', "phase-2-ovarian-stimulation")]['start']; 
  const calendar2End = events[getArrayIdFromObject(events, 'id', "phase-4-recovery")]['end']; 



  const calendar1WeeksBetweenStartEnd = getWeeksBetweenDates(calendar1Start, calendar1End);
  const calendar2WeeksBetweenStartEnd = getWeeksBetweenDates(calendar2Start, calendar2End);

  const titleStartDate = moment(formattedStart).format('MMM D, YYYY');
  const titleEndDate = moment(formattedEnd).format('MMM D, YYYY');

  const [clinicalCalendarEvents, setClinicalCalendarEvents] = useState(); 
  // console.log(clinicalCalendarEvents);

  // console.log(555, events )

  
  function dayRender(eventInfo){
    const dateString = moment(eventInfo.date, 'YYYY-MM-DD');
    const isDay1 = dateString.format('YYYY-MM-DD') === events[getArrayIdFromObject(events, 'id', 'call-day-1')]['start'];

    if(isDesktop && 
      (eventInfo.dow === 0 || eventInfo.isMonthStart || isDay1) )
      {
     return <span className='month-day-shown'>{dateString.format('MMM DD')}</span>
    } else {
      return <span>{dateString.format('DD')}</span>
    }
   }
  
   async function beforePdfGeneration(){
    AlertPopup({
      title: `Confirm Consents and Start Date`,
      html: "By clicking Confirm, an email will be sent to procedures including this patient's start date and their consent package.",
      confirmButtonText: "Confirm",
      cancelButtonText: "Generate calendar only",
      confirmFunction:() => {onSendConsentEmail(); onExportPDF('.seven-week-calendar-container');},
      cancelFunction: () => { onExportPDF('.seven-week-calendar-container');},
      goBackFunction: () => {},
      customClass: {
        title: 'swal2-no-break-title', // Dynamically pass the class name
      },  
    })
   }

   // offer to send consent package, and then follow up with PDF generation
   async function onExportPDF(){
    toast.dismiss();
    setIsLoading(true);
    clinicalExportToPDF("team-ivf-clinical-calendar", setCalendarKey, setIsLoading, setPrintView)

  }
  async function onSendConsentEmail(){
    const recipientEmails = process.env.REACT_APP_ENV === 'development' ? [email] : ['proceduresarea@hannamfertility.com', 'IVFdates@hannamfertility.com'];
    await sendConsentEmail(patientInfo, calendarConfig, toast, recipientEmails, events);
  }

  
  return (
    <>
      <div className={`seven-week-calendar-container clinical-calendar pagebreak ${printView ? 'print-view' : ''}`} ref={innerRef}>
        {/* <div className='header-pdf-text'>For more detailed instructions,<br/> please see page 3</div> */}
        <h1 className='calendar-title'> <span className='text-capitalize'>{!isEmpty(name) ? name : 'Patient'}</span>, Clinical Calendar </h1>
        <div className='date-title-container'> 
          <div className='date-title'> 
            <div className='your-dates'>Your Dates:</div><div className='date-string'>{titleStartDate} – {titleEndDate}</div> 
          </div>
        </div>
        <div className='btn-container'>
        <button className='btn btn-primary' onClick={scrollToDescription}>Scroll to Medication Overview</button>
        <button className='btn btn-primary' disabled={isEmpty(clinicalCalendarEvents)} onClick={() => setShowEditorModal(true)}>Edit Events</button>
        <button className='btn btn-primary' onClick={() => beforePdfGeneration()}>Export to PDF</button>
        </div>
        <FullCalendar
          dayCellContent ={dayRender}
          stickyHeaderDates={true}
          expandRows={true}    
          key={calendarKey} // informs the calendar to regenerate if start/end dates change
          themeSystem='bootstrap5'
          plugins={
            [dayGridPlugin, bootstrap5Plugin]}
          initialView="dayGridSevenWeek" // Use dayGridMonth as the initial view
          views= {{
            dayGridSevenWeek: {
              type: 'dayGrid',
              duration: { weeks: calendar1WeeksBetweenStartEnd }}
          }}
          monthStartFormat={{month: 'short', day:'numeric' }}
          validRange={{
            start: calendar1Start,
            end: calendar1End
          }}    
          headerToolbar={{
            left:   '',
            center: '',
            right:  ''
          }}
          contentHeight={printView ? '2700px': undefined}
          // height='auto'
          initialDate={calendar1Start}
          // aspectRatio={1.5}
          events={clinicalCalendarEvents}
          eventContent={(info) => <ClinicalEventContent innerProps={info} printView={printView}></ClinicalEventContent>}
          // windowResize={() => {
          //   // console.log('resize!!!!!!!!!!')
          //   // setKey(key+1);

          // }}
        />
      </div>




      <div className={`seven-week-calendar-container clinical-calendar pagebreak ${printView ? 'print-view' : ''}`}>
      <h1 className='calendar-title'> <span className='text-capitalize'>{!isEmpty(name) ? name : 'Patient'}</span>, Clinical Calendar </h1>
        <FullCalendar
          dayCellContent ={dayRender}
          stickyHeaderDates={true}
          expandRows={true}    
          key={calendarKey+2} // informs the calendar to regenerate if start/end dates change
          themeSystem='bootstrap5'
          plugins={
            [dayGridPlugin, bootstrap5Plugin]}
          initialView="dayGridSevenWeek" // Use dayGridMonth as the initial view
          views= {{
            dayGridSevenWeek: {
              type: 'dayGrid',
              duration: { weeks: calendar2WeeksBetweenStartEnd }}
          }}
          monthStartFormat={{month: 'short', day:'numeric' }}
          validRange={{
            start: calendar2Start,
            end: calendar2End
          }}    
          headerToolbar={{
            left:   '',
            center: '',
            right:  ''
          }}
          contentHeight={printView ? '2900px': undefined}
          // height='auto'
          initialDate={calendar2Start}
          // aspectRatio={1.2}
           events={clinicalCalendarEvents}
          eventContent={(info) => <ClinicalEventContent innerProps={info} printView={printView}></ClinicalEventContent>}
          // windowResize={() => {
          //   // console.log('resize!!!!!!!!!!')
          //   // setKey(key+1);

          // }}
        />
      </div>
    </>
    );
}

export default ClinicalCalendar;




