import moment from "moment";
import { STIM_DAY1_BLACKOUTS } from "../config";
import { checkRangeConflicts, generateBlackoutRanges } from "./eventGenerator";
import { getArrayIdFromObject, isEmpty } from "./common";

export function getStimDay1Overlaps(events, stimType){
    const stimDay1Blackouts = STIM_DAY1_BLACKOUTS[stimType];
    const eventIndex = getArrayIdFromObject(events, 'id', 'phase-2-ovarian-stimulation');
    const stimEvent = events[eventIndex];
    const calendarEventRanges = { // just stim start here
      range: moment.range(stimEvent.start, stimEvent.start),
    }
    const blackoutRanges = generateBlackoutRanges(stimDay1Blackouts, 'clinical');
    return checkRangeConflicts(calendarEventRanges, blackoutRanges)
  }
  
  export function generateStimBlackoutRecommendation(overlapsStimDay1Blackout, events, stimType, includeRecommendation) {
    const eventIndex = getArrayIdFromObject(events, 'id', 'phase-2-ovarian-stimulation');
    const stimEventStart = moment(events[eventIndex].start);
    const blackoutRanges = generateBlackoutRanges(STIM_DAY1_BLACKOUTS[stimType], 'clinical');
  
    // Try extending pretreatment by 1 week
    const extendedStart = stimEventStart.clone().add(1, 'weeks');
    const extendedCalendarEventRange = { range: moment.range(extendedStart, extendedStart) };
    const extendedOverlapConflicts = checkRangeConflicts(extendedCalendarEventRange, blackoutRanges);
  
    // Try reducing pretreatment by 1 week
    const reducedStart = stimEventStart.clone().subtract(1, 'weeks');
    const reducedCalendarEventRange = { range: moment.range(reducedStart, reducedStart) };
    const reducedOverlapConflicts = checkRangeConflicts(reducedCalendarEventRange, blackoutRanges);
  
    // Generate the final output text
    const stimEventStartFormatted = stimEventStart.format('MMM D, YYYY');
    const blackoutRange = overlapsStimDay1Blackout[0]; // Pull the first blackout range for simplicity
    const blackoutStart = blackoutRange.range.start.format('MMM D, YYYY');
    const blackoutEnd = blackoutRange.range.end.format('MMM D, YYYY');
    console.log('gdssffsdf', blackoutRange, blackoutRange.reason, 22222222)

    if (isEmpty(extendedOverlapConflicts)) {
      return (
        <div className='toast-warning-text-color p-2'>
          <span className='toast-header-blackout'><b>Stim Day 1 conflict ({stimEventStartFormatted}) </b></span><br/><br/>
          Ovarian Stimulation cannot begin between dates <b>{blackoutStart} - {blackoutEnd}</b> due to {blackoutRange.reason}.
          <br/><br/>
          The next available stim start based on clinic blackout dates is <b>{extendedStart.format('MMM D, YYYY')}</b>. 
          {includeRecommendation && <><br/><b>Please extend pretreatment by 1 week.</b></>}
        </div>
      );
    } else if (isEmpty(reducedOverlapConflicts)) {
      return (
        <div className='toast-warning-text-color p-2'>
          <span className='toast-header-blackout'><b>Stim Day 1 conflict ({stimEventStartFormatted}) </b></span><br/><br/>
          Ovarian Stimulation cannot begin between dates <b>{blackoutStart} - {blackoutEnd}</b> due to {blackoutRange.reason}.
          <br /><br />
          An available stim start based on clinic blackout dates is <b>{reducedStart.format('MMM D, YYYY')}</b>. 
          {includeRecommendation && <><br/><b>Please reduce pretreatment by 1 week.</b></>}
        </div>
      );
    } else {
      return (
        <div className='toast-warning-text-color p-2'>
          <span className='toast-header-blackout'><b>Stim Day 1 conflict ({stimEventStartFormatted}) </b></span><br/><br/>
          Ovarian Stimulation cannot begin between dates <b>{blackoutStart} - {blackoutEnd}</b> due to {blackoutRange.reason}.
          <br /><br />
          The week before and after Stim Day 1 are both unavailable due to blackout dates. 
          {includeRecommendation && <><br/><b>Recommend that the patient calls in with their next Day 1. </b></>}
          </div>
      );
    }
  }
  