import axios from "axios";
import { generateDateRanges, isEmpty } from "../common";
import * as Sentry from '@sentry/react';
import moment from "moment";

const VENNA_MIDDLEWARE_BASE_URL = process.env.REACT_APP_VENNA_MIDDLEWARE_BASE_URL;
const VENNAPLAN_API_BASE_URL = process.env.REACT_APP_VENNAPLAN_API_BASE_URL;


async function generateVennaPlan({amh, weight, takingAntibiotics,  existingPatientMedications, heightFt=5, heightIn=2}) {
    const payload = {
        plan_type: 'Egg Freezing',
        amh: amh,
        amh_unit: "pmol/L",
        weight: weight,
        weight_unit: "imperial system (ft/in and lbs)",
        height_ft: heightFt,
        height_in: heightIn,
        height_unit: "imperial system (ft/in and lbs)",
        age: 26,
        cycle_funding: 'Private',
        meds_taken: existingPatientMedications,
        };
    if (takingAntibiotics) payload.peri_retrieval = "Amoxiclav protocol"

    const headers = {'Content-Type': 'application/json'};
    return await axios.post(
        `${VENNAPLAN_API_BASE_URL}/vennaplan/generate_plan/`, payload,  { headers })
}

function getConsentPackageType(patientInfo) {
    if (patientInfo.planType === 'EF') {
        return 'Egg Freezing';
    } else if (patientInfo.planType === 'IVF' && (patientInfo.spermSource === "Fresh ejaculate, recommend frozen backup" || patientInfo.spermSource === "Frozen ejaculate")) {
        return 'IVF with Partner Sperm';
    } else if (patientInfo.planType === 'IVF' && patientInfo.spermSource === "Frozen donor sperm, donor number to be added") {
        if (patientInfo.reciprocalIVF) {
            return 'IVF with Donor Sperm (Reciprocal)';
        } else {
            return 'IVF with Donor Sperm';
        }
    } else {
        throw new Error('Consent Package not Found');
    }
};

async function sendConsentEmail(patientInfo, calendarConfig, toast, recipientEmails, clinicalCalendarEvents) {
   try{
        const startDate = moment(calendarConfig.startDate);
        const stimStartDate = moment(generateDateRanges(clinicalCalendarEvents).phase2['start']).clone();
        const consentPackageType = getConsentPackageType(patientInfo);
        const paymentTypeRaw = patientInfo?.rawTreatmentPlan?.['Cycle Funding'] ?? 'Data not found (Plan generated in Formstack)';
        const paymentType = paymentTypeRaw.includes('Funded') ? 'Funded' : paymentTypeRaw;

        const payload = {
            first_name: patientInfo.name,
            last_name: '',
            accuro_id: patientInfo.accuroId ?? 'None provided (Plan generated in Formstack)',
            consent_package_type: consentPackageType,
            recipient_emails: recipientEmails,
            start_date: startDate,
            stim_start_date: stimStartDate,
            payment_type: paymentType,
          };
    
        const headers = {'Content-Type': 'application/json'};
        const response = await axios.post(

            `${VENNAPLAN_API_BASE_URL}/vennaplan/send_consent_package/`, payload,  { headers })
        toast.success('Consent Email sent successfully', {autoClose: false});
        
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
        toast.error('Failed to send Consent Email. Please try again, then contact an administrator')
    }
}

export {
    generateVennaPlan,
    sendConsentEmail,
};


 