const CLINICAL_BLACKOUTS = [
    // {'title':'Christmas','start':new Date('12-26-2023'), 'end':new Date('1-2-2024'), type: 'clinic'},
    // {'title':'','start':'', 'end':'', type: 'clinic'},
    // {'title':'','start':'', 'end':'', type: 'clinic'},
]

const STIM_DAY1_BLACKOUTS = {
    'EF': [
        // {'title':'Stim break','start':new Date('11-02-2024'), 'end':new Date('11-08-2024'), type: 'clinic'},
        {'title':'Christmas','start':new Date('12-10-2024'), 'end':new Date('12-22-2024'), type: 'clinic', reason: 'a Christmas closure in our lab', description:<><p className="text-start">This date is not available to start your cycle.</p><p className="text-start"> Our IVF lab caps volumes between December 24 - January 2nd, which means that no new cycles can begin. To plan your cycle using this tool, use your next predicted period that will fall after November 27th.  To make sure this will not impact you, please contact your clinical team through email at <a style={{color: 'blue'}} href="mailto:teamivf@hannamfertility.com">teamivf@hannamfertility.com</a>.</p></>},
    ],
    'IVF': [
        // {'title':'Stim break','start':new Date('11-02-2024'), 'end':new Date('11-08-2024'), type: 'clinic'},
        {'title':'Christmas','start':new Date('12-04-2024'), 'end':new Date('12-22-2024'), type: 'clinic', reason: 'a Christmas closure in our lab', description:<><p className="text-start">This date is not available to start your cycle. </p><p className="text-start"> Our IVF lab caps volumes between December 24 - January 2nd, which means that no new cycles can begin. To plan your cycle using this tool, use your next predicted period that will fall after November 27th.  To make sure this will not impact you, please contact your clinical team through email at <a style={{color: 'blue'}} href="mailto:teamivf@hannamfertility.com">teamivf@hannamfertility.com</a>.</p></>},
    ]
}



export {
    CLINICAL_BLACKOUTS,
    STIM_DAY1_BLACKOUTS,
}