import React, { createContext, useState, useContext } from 'react';
import LoadingSpinner from '../Components/common/loadingSpinner';

const LoadingContext = createContext();
export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  console.log(12345, isLoading);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
        {isLoading && <LoadingSpinner/>}    
        {children}
    </LoadingContext.Provider>
  );
};



