import React, { createContext, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  return (
    <ErrorContext.Provider value={toast}>
        <ToastContainer
            position="top-right"
            className={''}
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            // closeOnClick
            pauseOnFocusLoss
            // draggable
            pauseOnHover
            theme="colored"
        />
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
