import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { isBrowser, isMobile } from 'react-device-detect';
import PDFMerger from 'pdf-merger-js';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';


// Hide inset boxshadow in PDF, as they're busted
// currently used in background events in full calendar
const hideInsetBoxShadow = (doc) => { 
  const bgEvents = doc.getElementsByClassName('fc-bg-event');
  for (let i = 0; i < bgEvents.length; i++) {
    bgEvents[i].style.boxShadow = 'none';
  }

  const dayCells = doc.getElementsByTagName('td');
  for (let i = 0; i < dayCells.length; i++) {
    dayCells[i].style.boxShadow = 'none';
  }

  const tables = doc.getElementsByTagName('table');
  for (let i = 0; i < tables.length; i++) {
    tables[i].style.boxShadow = 'none';
  }
  const buttons = doc.getElementsByTagName('button');
  for (let i = 0; i < buttons.length; i++) {
    buttons[i].style.display = 'none';
  }

  const loadingPage = doc.getElementsByClassName('spinner-wrapper');
  if (loadingPage[0]) loadingPage[0].style.display = 'none';
}

// portrait view is used to show more vertical space in description view - no need for this to be horizontal
// It works by rotating an image to preserve its 'portraiteness', then attaching it to the existing landscape pdf
async function generatePortraitPDF(pdf, descriptionClass) {
  return new Promise((resolve) => {
    const elements = document.querySelectorAll(descriptionClass);
    const promiseArray = Array.from(elements).map(element => {
      return html2canvas(element, {
        onclone: (doc) => hideInsetBoxShadow(doc),
        scale: 1
      });
    });

    Promise.all(promiseArray).then(canvases => {
      canvases.forEach((canvas, index) => {
        const ctx = canvas.getContext('2d');
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');

        tempCanvas.width = canvas.height;
        tempCanvas.height = canvas.width;

        tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2);
        tempCtx.rotate(Math.PI / 2);
        tempCtx.drawImage(canvas, -canvas.width / 2, -canvas.height / 2);

        const dataURL = tempCanvas.toDataURL('image/png');
        const imgProps = pdf.getImageProperties(dataURL);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = imgProps.width;
        const imgHeight = imgProps.height;

        // Calculate the width and height to fit the image into the PDF while maintaining aspect ratio
        const maxWidth = pdfWidth;
        const maxHeight = pdfHeight;

        let imgScaledWidth, imgScaledHeight;
        if (imgWidth / imgHeight > maxWidth / maxHeight) {
          // Image is wider in aspect ratio than the available space
          imgScaledWidth = maxWidth;
          imgScaledHeight = (imgHeight * maxWidth) / imgWidth;
        } else {
          // Image is taller in aspect ratio than the available space
          imgScaledWidth = (imgWidth * maxHeight) / imgHeight;
          imgScaledHeight = maxHeight;
        }
        // Center the image within the PDF page
        const xOffset = (pdfWidth - imgScaledWidth) / 2;
        const yOffset = (pdfHeight - imgScaledHeight) / 2;

        pdf.addPage();
        pdf.addImage(dataURL, 'PNG', xOffset, yOffset, imgScaledWidth, imgScaledHeight, 'description-img' + index, 'MEDIUM');
      });

      resolve(pdf);
    }).catch(error => {
      console.error(error);
      resolve(null);
    });
  });
}

// landscape view is used to show more horizontal space in calendar view - text was too small
async function generateLandscapePDF(pdf, calendarClass) {
  return new Promise((resolve) => {
    const elements = document.querySelectorAll(calendarClass);

    const promiseArray = Array.from(elements).map(element => {
      return html2canvas(element, {
        onclone: (doc) => hideInsetBoxShadow(doc),
        scale: 1
      });
    });

    Promise.all(promiseArray).then(canvases => {
      const totalPages = canvases.length;
      canvases.forEach((canvas, index) => {
        const dataURL = canvas.toDataURL('image/png');
        const imgProps = pdf.getImageProperties(dataURL);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = imgProps.width;
        const imgHeight = imgProps.height;
        
        // Calculate a uniform scaling factor slightly smaller than 1 to zoom out
        const zoomOutFactor = 1; // Adjust this value to control the zoom level
        const scaleFactor = (pdfWidth / imgWidth) * zoomOutFactor;

        // Apply the zoomed-out scaling factor to both dimensions
        const imgScaledWidth = imgWidth * scaleFactor;
        const imgScaledHeight = imgHeight * scaleFactor;

        // Center the image vertically within the PDF page
        const xOffset = (pdfWidth - imgScaledWidth) / 2; // Center horizontally
        const yOffset = (pdfHeight - imgScaledHeight) / 2; // Center vertically

        if (index > 0) {
          pdf.addPage();
        }

        // Set the background color to lavender
        pdf.setFillColor('#EBD6FF');
        pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

        pdf.addImage(dataURL, 'PNG', xOffset, 0, imgScaledWidth, imgScaledHeight, 'calendar-img' + index, 'MEDIUM');
        pdf.addImage('Hannam-Fertility-Logo-Black-cropped.png', 'PNG', 1, 0.5, 22, 9);
        addText(pdf, `Page ${index + 1} of ${totalPages}`, pdfWidth - 25, pdfHeight - 3, 10);
      });

      resolve(pdf);
    }).catch(error => {
      console.error(error);
      resolve(null);
    });
  });
}

function addText(pdf, text, x, y, fontSize) {
  pdf.setFontSize(fontSize);
  pdf.setTextColor('black');
  pdf.text(text, x, y);
}

function addHighlightedText(pdf, text, x, y, highlightColor, fontSize) {
  pdf.setFontSize(fontSize);
  const textWidth = pdf.getTextWidth(text);
  const textHeight = pdf.getFontSize() / pdf.internal.scaleFactor;
  
  pdf.setFillColor(highlightColor);
  pdf.rect(x - 1, y - textHeight, textWidth + 2, textHeight + 2, 'F');

  pdf.setTextColor('black');
  pdf.text(text, x, y);
}

// prepares DOM to be printed. Sizing adjustments for PDF print
function prepareUIForExport(regenerateKey, setPrintView, calendarClass, descriptionClass) {
    const calendarPrintWidth = '4500px'; // portrait
    const descriptionPrintWidth = '2500px'; // landscape
    setPrintView(true);// communicates with EventContent to render Desktop UI version of events, despite the viewport not changing

  // force the calendar DOM to extend to the intended pdf width
    const calendars = document.querySelectorAll(calendarClass);
    for (let i = 0; i < calendars.length; i++) {
      calendars[i].style.width = calendarPrintWidth;
    }

    document.querySelector(descriptionClass).style.width = descriptionPrintWidth;    

}

async function clinicalExportToPDF(fileName, regenerateKey, setIsLoading, setPrintView) {
  const calendarClass = '.clinical-calendar';
  const descriptionClass = '.medication-details';
  const originalCalWidth = document.querySelector(calendarClass).style.width; 
  const originalDescWidth = document.querySelector(descriptionClass).style.width; 
  try {
  
    // Switch to print view for the PDF print
    prepareUIForExport(regenerateKey, setPrintView, calendarClass, descriptionClass);
    // Yield control to allow React to render output from prepareUIForExport
    await new Promise((resolve) => setTimeout(resolve, 0));

    // Generate and save PDF
    const pdf = new jsPDF({ orientation: 'landscape' });
    const landscapePDF = await generateLandscapePDF(pdf, calendarClass + ', ' + descriptionClass);
    landscapePDF.save(fileName);

  } catch(e) {
      console.log(e);
      toast.error('The PDF failed to generate. Please try again, then contact an administrator');
      Sentry.captureException(e);
  } finally {
    setIsLoading(false);
    setPrintView(false);
    // Restore original styles
    document.querySelector(descriptionClass).style.width = originalDescWidth;
    const calendars = document.querySelectorAll(calendarClass);
    for (let i = 0; i < calendars.length; i++) {
      calendars[i].style.width = originalCalWidth; 
    }

    }
};


export {
  clinicalExportToPDF
}
