import React, { useState, useEffect   } from 'react';

import { Dna } from 'react-loader-spinner';
import FullCalendar from '@fullcalendar/react';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';

import moment from 'moment/moment';
import eventGenerator from '../../js/eventGenerator';
import ClinicalEventContent from './ClinicalEventContent';
import { getArrayIdFromObject, getWeeksBetweenDates, areDatesEqual } from '../../js/common';
import { exportToPDF } from '../../js/exportToPdf';
import LoadingSpinner from '../common/loadingSpinner';
import { isEmpty } from '../../js/common';
import { useMediaQuery } from 'react-responsive';
import { getPhase1Desc, getPhase2Desc, getPhase3Desc, getPhase4Desc, getNoMedsDesc } from '../../js/medications';
import { Col } from 'react-bootstrap';

function generateDateRanges(events){
  const phase1 = events[getArrayIdFromObject(events, 'id', "phase-1-meds")]; 
  const phase2 = events[getArrayIdFromObject(events, 'id', "phase-2-ovarian-stimulation")];
  const phase3 = events[getArrayIdFromObject(events, 'id', "phase-3-trigger-retrieval")];
  const phase4 = events[getArrayIdFromObject(events, 'id', "phase-4-recovery")];
  const noMeds = events[getArrayIdFromObject(events, 'id', "no-medications-2")];
  const cycleOrientation = events[getArrayIdFromObject(events, 'id', "cycle-review-datetime")];
  const monitoringAppointments = events.filter(event => event.id.startsWith("clinic-visit-monitoring"));

 return {
    phase1,
    phase2,
    phase3,
    phase4,
    noMeds,
    cycleOrientation,
    monitoringAppointments
  }
}


const MedicationDetails = ({events, medicationList, startDate, setStartDate, patientBlackouts = [], setShowEditorModal, setCalendarKey, calendarKey, innerRef, patientInfo: {name = 'Patient', amh, weight, takingAntibiotics}, stimStartDay}) => {
  
//   useEffect(() => {
//   if (!isEmpty(innerRef.current)) {
//     innerRef.current.scrollIntoView({ behavior: "smooth"});    
//   }  
// }, [calendarKey]);


  const [printView, setPrintView] = useState(false); 
  const [isEditable] = useState(new URLSearchParams(window.location.search).get('clinician-mode') === '1');

  const printWidth = printView ? {width: 961} : undefined;
  const isDesktop = useMediaQuery({ query: `(min-width: 961px)` }, printWidth);

  const formattedStart = events[getArrayIdFromObject(events, 'id', "call-day-1")]['start']; // set get the first blackout event to indicate the start of the calendar
  const formattedEnd = events.slice(-1)[0]['end']; // set final event's "end" to be the calendar's official end date
  const weeksBetweenStartEnd = getWeeksBetweenDates(formattedStart, formattedEnd);

  const titleStartDate = moment(formattedStart).format('MMM D, YYYY');
  const titleEndDate = moment(formattedEnd).format('MMM D, YYYY');

  const [dateRanges, setDateRanges] = useState(generateDateRanges(events));


  
  function formatDateString(dateString) {
    return moment(dateString).format('MMMM D, YYYY');
  }
  useEffect(() => {
    const fetchDescriptions = async () => {

        const monitoringAppointments = dateRanges.monitoringAppointments;

        const phase1 =  await getPhase1Desc(dateRanges.phase1, amh, weight, dateRanges.cycleOrientation, monitoringAppointments, medicationList);
        const phase2 =  await getPhase2Desc(dateRanges.phase2, amh, weight, monitoringAppointments, stimStartDay, medicationList, );
        const phase3 =  await getPhase3Desc(dateRanges.phase3, amh, weight, takingAntibiotics, monitoringAppointments, medicationList);
        const phase4 =  await getPhase4Desc(dateRanges.phase4, amh, weight, monitoringAppointments, medicationList);
        const noMeds =  await getNoMedsDesc(dateRanges.noMeds, amh, weight, monitoringAppointments, stimStartDay, medicationList);
        // console.log(phase1, phase2, phase3, phase4)

        setPhase1Desc(phase1);
        setPhase2Desc(phase2);
        setPhase3Desc(phase3);
        setPhase4Desc(phase4);
        setNoMedsDesc(noMeds);
    }
    fetchDescriptions();

  }, [calendarKey]);

  const [phase1Desc, setPhase1Desc] = useState(null);
  const [phase2Desc, setPhase2Desc] = useState(null);
  const [phase3Desc, setPhase3Desc] = useState(null);
  const [phase4Desc, setPhase4Desc] = useState(null);
  const [noMedsDesc, setNoMedsDesc] = useState(null);

  return (
    <div className='medication-details seven-week-calendar-container row print-view pagebreak' ref={innerRef} >
      <h1 className='calendar-title mb-3'> <span className='text-capitalize'>{!isEmpty(name) ? name : 'Patient'}</span>, Written Treatment Instructions </h1>
      <Col md={4}>
        <div className='desc-panel phase-1-desc h-80'>
          <div className='header-container'>
            <h3>{dateRanges.phase1['extendedProps']['icon']} &nbsp; Phase 1: Pre Treatment</h3>
            {/* full calendar end dates are exclusive, so reduce them by 1 for moment to accurately get the last week */}
            <h4>{formatDateString(dateRanges.phase1['start'])} - {formatDateString(moment(dateRanges.phase1['end']).clone().subtract(1, 'days'))} </h4> 
          </div>
          <div className='container-body'> 
            {phase1Desc}
          </div>
        </div>

        <div className='desc-panel no-meds-desc h-20'>
          <div className='header-container'>
            <h3>&nbsp; Washout Phase</h3>
            {/* full calendar end dates are exclusive, so reduce them by 1 for moment to accurately get the last week */}
            <h4>{formatDateString(dateRanges.noMeds['start'])} - {formatDateString(moment(dateRanges.noMeds['end']).clone().subtract(1, 'days'))} </h4> 
          </div>
          <div className='container-body'> 
            {/* <ul><li> */}
                {/* <b>{moment((dateRanges.noMeds['start'])).format("MMMM Do")} - {(moment(dateRanges.noMeds['end']).clone().subtract(1, 'days')).format("MMMM Do")}: </b> */}
              No medications required. You may get a period during this week. You do not need to report this to clinical team.
              {/* </li></ul> */}
              {/* {noMedsDesc} */}
          </div>
        </div>
      </Col>
      <Col md={4}>
        <div className='desc-panel phase-2-desc h-100'>
          <div className='header-container'>  
            <h3>{dateRanges.phase2['extendedProps']['icon']} &nbsp; Phase 2: Ovarian Stimulation  </h3>
            {/* full calendar end dates are exclusive, so reduce them by 1 for moment to accurately get the last week */}
            <h4>{formatDateString(dateRanges.phase2['start'])} - {formatDateString(moment(dateRanges.phase2['end']).clone().subtract(1, 'days'))} </h4> 
          </div>
          <div className='container-body'> 
            <p>
            Please note all medication instructions will be confirmed by your clinical nursing team. This calendar is meant to serve as a guide, and could change depending on monitoring results. The nursing team will phone after every clinical visit to confirm instructions.
            <br/> <b>Pending confirmation from nursing team via phone call:</b>
            </p>
            {noMedsDesc} {/* Jul 29 2024 - moving prestim appointments over from nomeds panel to phase 2  */}
            {phase2Desc}
          </div>  
        </div>
      </Col>
      <Col md={4}>
        <div className='desc-panel phase-3-desc'>
          <div className='header-container'>  
            <h3> {dateRanges.phase3['extendedProps']['icon']} &nbsp; Phase 3: Trigger and Retrieval Window </h3>
            {/* full calendar end dates are exclusive, so reduce them by 1 for moment to accurately get the last week */}
            <h4>{formatDateString(dateRanges.phase3['start'])} - {formatDateString(moment(dateRanges.phase3['end']).clone().subtract(1, 'days'))} </h4> 
          </div>
          <div className='container-body'> 
            <p>
              Please note all medication instructions will be confirmed by your clinical nursing team. This calendar is meant to serve as a guide, and could change depending on monitoring results. Specific trigger instructions and timing of medications will be communicated by phone, and a detailed email will be provided by your team.
              <br/> <b>Pending confirmation from nursing team via phone call:</b>
            </p>
            {phase3Desc}
          </div>
        </div>
        <div className='desc-panel phase-4-desc'>
        <div className='header-container'>  
          <h3> {dateRanges.phase4['extendedProps']['icon']} &nbsp;Phase 4: Recovery   </h3>
          {/* full calendar end dates are exclusive, so reduce them by 1 for moment to accurately get the last week */}
          <h4>{formatDateString(dateRanges.phase4['start'])} - {formatDateString(moment(dateRanges.phase4['end']).clone().subtract(1, 'days'))} </h4> 
        </div>
        <div className='container-body'> 
          {phase4Desc}
        </div>
        </div>

      </Col>

    </div>
  );


}
export default MedicationDetails;




