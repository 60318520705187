import axios from "axios";
import { isEmpty } from "../common";

const VENNA_MIDDLEWARE_BASE_URL = process.env.REACT_APP_VENNA_MIDDLEWARE_BASE_URL;

async function getAccessToken(microsoftAuthToken) {
    const tokenResponse = await axios.get(
        `${VENNA_MIDDLEWARE_BASE_URL}/get_access_token`,
        {headers: {'Authorization': `Bearer ${microsoftAuthToken}`, 'x-app-name': process.env.REACT_APP_APP_NAME, 'x-tenant-name': 'HFC'}}
    );
    return tokenResponse.data.access_token;
}

// frontend endpoint to fetch actual patient ID using a provided fileNumber
async function fetchPatientDemographics(accessToken, patientFileNumber) {
    const patientResponse = await axios.get(
        `${VENNA_MIDDLEWARE_BASE_URL}/fetch_patient_details?fileNumber=${patientFileNumber}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
    
    if (!isEmpty(patientResponse?.data?.contents)) {
        return {
            realPatientId: patientResponse.data.contents[0].patientId,
            name: `${patientResponse.data.contents[0]?.demographics['firstName']} ${patientResponse.data.contents[0]?.demographics['lastName']}` ,
        };
    } else {
        throw new Error('Patient ID not found');
    }
}

async function getLabResults(accessToken, realPatientId) {
    const patientResponse = await axios.get(
    `${VENNA_MIDDLEWARE_BASE_URL}/fetch_lab_results?patientId=${realPatientId}`,
    {
        headers: { Authorization: `Bearer ${accessToken}` },
    }
    );

    return patientResponse.data;
}

async function fetchLatestPlanFromDatalake(microsoftAuthToken, accuroId) {
    const REACT_APP_DATALAKE_PLAN_PATH = process.env.REACT_APP_DATALAKE_PLAN_PATH
    if (isEmpty(accuroId)){
        throw new Error('Accuro ID is required to pull existing patient plans')
    }
    const response = await axios.get(
        `${VENNA_MIDDLEWARE_BASE_URL}/download-datalake?accuroId=${accuroId}&blobPath=${REACT_APP_DATALAKE_PLAN_PATH}&latest=true`,
        {headers: {'Authorization': `Bearer ${microsoftAuthToken}`, 'x-app-name': process.env.REACT_APP_APP_NAME, 'x-tenant-name': 'HFC'}}
    );
    return response.data;
}

export {
    getLabResults,
    fetchPatientDemographics,
    getAccessToken,
    fetchLatestPlanFromDatalake,
};
