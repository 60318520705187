import React, { useState, useEffect, useRef} from 'react';

import FullCalendar from '@fullcalendar/react';

import moment from 'moment/moment';
import eventGenerator, { generateStimBlackoutAlert } from '../../js/eventGenerator';
import { getArrayIdFromObject, getWeeksBetweenDates, areDatesEqual, cascadeEventList} from '../../js/common';
import { isEmpty } from '../../js/common';
import EditEventInput from './EditEventInput';
import CommonModal from '../common/CommonModal';
import AlertPopup from '../common/AlertPopup';
import { Col, Form, Row } from 'react-bootstrap';
import CascadeForm from './CascadeForm';
import Accordion from 'react-bootstrap/Accordion';
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import InfoPopover from '../common/InfoPopover';
import { useError } from '../../context/ErrorContext';

const getSortedEventsByStartDate = (eventArray) => {
  return eventArray.sort((a, b) => a.start.localeCompare(b.start));
}

const getEditOptionDisabledState = (calendarEdits) => {
  // Count the number of extend and reduce operations
  const extendCount = calendarEdits.filter(edit => edit === 'extend-pre-1').length;
  const reduceCount = calendarEdits.filter(edit => edit === 'reduce-pre-1').length;

  // Determine if the extend or reduce options should be disabled
  const extendDisabled = extendCount - reduceCount >= 1;
  const reduceDisabled = reduceCount - extendCount >= 1;

  return { extendDisabled, reduceDisabled };
};


const EventEditor = ({events, calendarKey, setCalendarKey, onSubmit, closeModal, calendarMode, calendarEdits, calendarConfig, setCalendarEdits, eventsOriginalState}) => {
  const toast = useError();
  const [editedEvents, setEditedEvents] = useState(getSortedEventsByStartDate(events));
  const [editOption , setEditOption] = useState();

  const alertRef = useRef();

  const onSubmitEvents = () => {
    // console.log('old events list', events)
    // console.log('edited events list', editedEvents)
    console.log(editedEvents)
    onSubmit(editedEvents);
  }

  const onManualEventEdit = (id, editedEvent, allowCascade=true) => {
    let newEventsList = [...editedEvents];
    const phaseIds = ['phase-1-meds', 'phase-2-ovarian-stimulation', 'phase-3-trigger-retrieval', 'phase-4-recovery']
    const eventIndex = getArrayIdFromObject(newEventsList, 'id', id);
    const originalEvent = newEventsList[eventIndex]

    const eventToUpdate = {
      ...editedEvents[eventIndex],
      start: editedEvent.start,
      end: editedEvent.end, 
      extendedProps: {...editedEvents[eventIndex].extendedProps, description: editedEvent.description}
    };
    
    const daysMovedForward = moment.duration(moment(editedEvent.end).diff(moment(originalEvent.end))).asDays();
    const endDateMovedToFuture = daysMovedForward > 0;

    if (allowCascade &&  phaseIds.includes(id) && endDateMovedToFuture){    
      cascadeEditPrompt(eventToUpdate, daysMovedForward); // ensure cascadeEditPrompt takes care of saving the original edited event
    }  else {
      newEventsList[eventIndex] = eventToUpdate;
      setEditedEvents(newEventsList);
    }
  }

  // Note - 1-16-2023 A React issue: When called within the context of The Alert's "CascadeForm", bulkEventEdit will lock in state context from the moment it was called. 
  // Any state updates done after the Alert pops up may be missed here 
  const bulkEventEdit = (events) => {
    let editedEventsList = [...editedEvents];
    for (const editedEvent of Object.values(events)){
      const eventIndex = getArrayIdFromObject(editedEventsList, 'id', editedEvent.id);
      editedEventsList[eventIndex] = editedEvent;
    }
    setEditedEvents(editedEventsList);
    return editedEventsList;
  }



  const handleAlertConfirm = () => {
    onSubmitEvents();
    closeModal();
  };

  const handleAlertCancel = () => {
    closeModal();
  };

  const handleAlertGoBack = () => {
    // do nothing
  };

  const openAlert = () => {
    AlertPopup({
      title: "Confirmation",
      text: "Would you like to save your changes?",
      confirmButtonText: "Save and Close",
      confirmFunction: () => handleAlertConfirm(),
      cancelButtonText: "Discard Changes",
      cancelFunction: () => handleAlertCancel(),
      goBackFunction: () => handleAlertGoBack(),
    });
  };  

  // Note - 1-16-2023 A React issue: When bulkEventEdit is called within the context of The Alert's "CascadeForm", bulkEventEdit will lock in state context from the moment it was called. 
  // Any state updates done after the Alert pops up may be missed here. 
  const cascadeEditPrompt = (editedEvent, daysToCascade) => {
    AlertPopup({
      ref: alertRef,
      title: <h1> {editedEvent.title}</h1>,
      html: <CascadeForm editedEvent={editedEvent} originalEvents={editedEvents} onCascadeConfirm={bulkEventEdit} alertRef={alertRef} daysToCascade={daysToCascade}/>,
      // confirmButtonText: "Confirm",
      // confirmFunction: (e) => handleAlertConfirm(e),
      // cancelButtonText: "Discard Changes",
      // cancelFunction: () => handleAlertCancel(),
      // goBackFunction: () => handleAlertGoBack(),
    });
  };

  const editOptions = [
    { value: 'extend-pre-1', label: 'Extend Pre-treatment by 1 week', confirmationMsg:'Please confirm that you\'d like to extend Pre-Treatment by 1 week.', targetEventId: 'phase-1-meds' },
    { value: 'reduce-pre-1', label: 'Reduce Pre-treatment by 1 week', confirmationMsg:'Please confirm that you\'d like to reduce Pre-treatment by 1 week.', targetEventId: 'phase-1-meds' },
    { value: 'reset-calendar', label: 'Revert all edits', confirmationMsg:'Please confirm that you\'d like to reset the calendar to its original state.' },
  ]

  const editSelectionConfirm = () => {
    AlertPopup({
      title: "Confirmation",
      text: editOption.confirmationMsg,
      confirmButtonText: "Confirm",
      confirmFunction: () => handlePreselectedEdit(),
      cancelButtonText: "Discard Changes",
      cancelFunction: () => {},
      goBackFunction: () => {},
    });
  };  

  const handlePreselectedEdit = () => {
    let newEventsList = [...editedEvents];
    let eventToUpdate = undefined;
    let originalEvent = undefined;

    if (!isEmpty(editOption.targetEventId)){
      const eventIndex = getArrayIdFromObject(newEventsList, 'id', editOption.targetEventId);
      originalEvent = newEventsList[eventIndex]
  
      // deep clone event so we don't modify the originalEvents object, since JS arrays are mutable
      // the targetted event will be modified in the below switch statements
      eventToUpdate = {
        ...editedEvents[eventIndex],
        start: originalEvent.start,
        end: originalEvent.end,
        extendedProps: {...originalEvent.extendedProps}
      };
    } 

    switch(editOption.value) {
      case 'extend-pre-1': {
        eventToUpdate.end = moment(originalEvent.end).clone().add(7, 'days').format('YYYY-MM-DD');

        // additionally update cycle orientation, as it is the only event that overlaps phase 1 that needs to also be pushed to future
        const cycleOrientationId = getArrayIdFromObject(newEventsList, 'id', 'cycle-orientation');
        let cycleOrientationEvent = newEventsList[cycleOrientationId]
        cycleOrientationEvent = {
          ...editedEvents[cycleOrientationId],
          start: moment(cycleOrientationEvent.start).add(7, 'days').format('YYYY-MM-DD'),
          end: moment(cycleOrientationEvent.end).add(7, 'days').format('YYYY-MM-DD'),
          extendedProps: {...cycleOrientationEvent.extendedProps}
        };    
        // cascade all future non-overlapping events
        const cascadedEvents = cascadeEventList(newEventsList, eventToUpdate);
        newEventsList = bulkEventEdit({...cascadedEvents, eventToUpdate, cycleOrientationEvent})

        onSubmit(newEventsList);
        setCalendarEdits([...calendarEdits, editOption.value]);
        closeModal();
        
        break;
      }
      case 'reduce-pre-1': {
        eventToUpdate.end = moment(originalEvent.end).clone().subtract(7, 'days').format('YYYY-MM-DD');
        const cascadedEvents = cascadeEventList(newEventsList, eventToUpdate);
        newEventsList = bulkEventEdit({...cascadedEvents, eventToUpdate})
        onSubmit(newEventsList);
        setCalendarEdits([...calendarEdits, editOption.value]);
        closeModal();
        break;
      }
      case 'reset-calendar': {
        newEventsList = eventsOriginalState;
        onSubmit(eventsOriginalState);
        setCalendarEdits([]);
        closeModal();
        break;
      }
      default:
        break;
    }
    // check for stim blackout conflicts after reducing pretreatment after edits
    toast.dismiss();
    const includeRecommendation = false;
    const stimBlackoutConflicts = generateStimBlackoutAlert(newEventsList, calendarConfig.stimType, calendarMode, includeRecommendation );
    if (stimBlackoutConflicts) toast.warning(stimBlackoutConflicts.blackoutWarningText, { autoClose: false, className: 'toast-dark-font' });
    

  }

  return (
      <CommonModal 
        onClose={() => openAlert()}
        title='Edit Events'
        body={
            <div className='event-editor'>
              <div className='event-edit-container'>
                <Select 
                  className="select-container" 
                  options={editOptions} 
                  onChange={setEditOption}
                  isOptionDisabled={(option) => {
                    const { extendDisabled, reduceDisabled } = getEditOptionDisabledState(calendarEdits);
                    if (option.value === 'extend-pre-1') return extendDisabled;
                    if (option.value === 'reduce-pre-1') return reduceDisabled;
                    return false;  // Don't disable for any other options for now
                  }}
                />
                {/* Info Popover next to the Select field */}
                <InfoPopover position={'top'} body={"You can only extend or reduce Pre-Treatment by 1 week."}>
                  <span className='clickable-container'>
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </span>
                </InfoPopover>

                <button class='btn btn-primary' disabled={isEmpty(editOption)} onClick={()=>editSelectionConfirm()} >Submit</button>
                </div>
                <br/>

              {calendarMode === 'patient' &&
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Advanced Editor</Accordion.Header>
                  <Accordion.Body>
                  <p> <b>Note: </b> Moving any <i>Phase</i> to the future will give you the option to automatically push subsequent events to the future.</p>
              {/* <button className='btn btn-primary' onClick={onSubmitEvents}>Save Changes</button> */}
              
                      {editedEvents.map((editedEvent) => {
                        return <div>
                          <div className={`edit-event ${editedEvent.className}`}>{editedEvent.title || editedEvent.id}</div>
                          <EditEventInput
                            event={editedEvent}
                            onEventEdit={onManualEventEdit}
                        />
                        <br/>
                        </div>
                      })
                    }
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }


            </div>
          }  
        />      
  );
}

export default EventEditor;




