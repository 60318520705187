import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import { isEmpty, convertLbsToKg, convertKgToLbs, getThreeDaysAgo, mapSpermSourceToLabel} from '../../js/common';
import DatePicker from "react-datepicker";
import DaySelector from './DaySelector';
import { getPatientLabAndDemographicData } from '../../js/accuro_utils';
import { useLoading } from '../../context/LoadingContext';
import { UserContext } from '../../context/UserContext';
import Select from 'react-select';
import { fetchLatestPlanFromDatalake } from '../../js/api/vennabackend';
import { useError } from '../../context/ErrorContext';
import * as Sentry from "@sentry/react";

function CalendarInput({ setPatientInfo, patientInfo, setCalendarKey, calendarMode, startDate, setStartDate, stimStartDay, setStimStartDay, adminMode}) {
  const toast = useError();
  const { setIsLoading } = useLoading(); 
  const { authToken } = useContext(UserContext);
  // const [eltroxinInPretreatment, setEltroxinInPretreatment] = useState(!isEmpty(patientInfo.eltroxinInPretreatment) ?  patientInfo.eltroxinInPretreatment : null);
  const [errors, setErrors] = useState({});
  const [accuroFieldsDisabled] = useState(calendarMode==='patient' || adminMode ? false : true );

    const formatTreatmentPlanForCalendar = (data) => {
      return {
        accuroId: data.accuroId || null,
        amh: data.amh || null,
        eltroxinInPretreatment: data.plan?.Adjuncts?.includes("Eltroxin protocol or current dose") || false,
        existingPatientMedications: data.medsTaken.some(med => med !== "None")? data.medsTaken.filter(med => med !== "None"): ["None"],      
        name: `${data.chosenName || data.rawEmrPatientInfo?.demographics?.firstName || ""} ${data.lastName || ""}`.trim(),
        spermSource: data.spermSource || undefined,
        planType: data.planType === 'IVF' ? 'IVF' : 'EF',
        takingAntibiotics: Boolean(data.meds_info?.["APO-Amoxi Clav"]),
        weight: data.weightLbs || null,
        reciprocalIVF: false, // Feb 3: not stored anywhere, but setting this to false so it acts as a default value when pulling data from our datalake
        rawTreatmentPlan: data?.plan,
        rawMedsInfo: data?.meds_info,
      };
    };
    
    const handleFetchExistingPlan = async (e) => {
      e.preventDefault();
      toast.dismiss();
      setIsLoading(true);
      setPatientInfo({accuroId: patientInfo.accuroId, existingPatientMedications: []});
    
      try {
        // Attempt to fetch from Datalake
        const datalakeData = await fetchLatestPlanFromDatalake(authToken, patientInfo.accuroId);
        console.log('Raw API data from datalake:', datalakeData);
    
        const formattedData = formatTreatmentPlanForCalendar(datalakeData);
        console.log('Formatted data:', formattedData, { ...patientInfo, ...formattedData });
    
        setPatientInfo(formattedData);
      } catch (datalakeError) {
        console.error('Failed to fetch treatment plan from datalake. Trying Accuro...', datalakeError);
        Sentry.captureMessage('Failed to fetch treatment plan from datalake. Trying Accuro...', {extra: {patientInfo}});
    
        try {
          // Fallback to fetch from Accuro
          const accuroData = await getPatientLabAndDemographicData(patientInfo.accuroId, authToken);
          console.log('Raw API data from Accuro:', accuroData);
    
          // Reuse original logic for setting up data from Accuro
          const amh = accuroData?.labData?.amh || '';
          const weight = accuroData?.labData?.weight || '';
          const name = accuroData.demographics?.name;
    
          // Merge data into patientInfo
          setPatientInfo({
            accuroId: patientInfo.accuroId,
            name,
            amh: !isEmpty(amh) ? Math.ceil(amh) : '',
            weight,
            existingPatientMedications: [],
          });
        } catch (accuroError) {
          console.error('Failed to fetch data from Accuro API:', accuroError);
          toast.error('Failed to fetch data from Accuro API. Please try again, then contact an administrator.');
          Sentry.captureMessage('Failed to fetch treatment plan from both datalake and Accuro', {extra: {patientInfo}});
        }
      } finally {
        setIsLoading(false);
      }
    };
        
  

  const handleMedicationChange = (e) => {
    const { value, checked } = e.target;
    let updatedMedications;
    if (value === "None" && checked) {
      // Clear the list and only include "None"
      updatedMedications = ["None"];
    } else {
      // Remove "None" if any other medication is selected
      updatedMedications = checked
        ? [...patientInfo.existingPatientMedications.filter(med => med !== "None"), value]
        : patientInfo.existingPatientMedications.filter(med => med !== value);
    }
    setPatientInfo({ ...patientInfo, existingPatientMedications: updatedMedications });
  };
    
  
  const handleChangeStimStart = (dayOfWeek) => {
    let stimOffset;

    switch (dayOfWeek.value) {
      case 'Monday':
        stimOffset = 0;
        break;
      case 'Saturday':
        stimOffset = -2;
        break;
      case 'Sunday':
        stimOffset = -1;
        break;
      default:
        console.log('No day selected');
    }
    setStimStartDay({stimStartDayOfWeek: dayOfWeek.value, stimOffset, label:dayOfWeek.label, value:dayOfWeek.value});

  };




  return (
    <div className="patient-info-form">
      <Form>
        <Form.Group controlId="patientInfo">




          { calendarMode === 'clinical' && 
            <div className="accuro-fetch">
              <Row>
                  <Form.Label column xs={12} xl={3}>Enter Accuro ID:</Form.Label>
                  <Col>
                    <>
                      <input
                        type="text"
                        value={patientInfo.accuroId || ''}
                        // onChange updates the centralized patientInfo state
                        onChange={(e) => setPatientInfo({ ...patientInfo, accuroId: e.target.value })}
                      />
                      &emsp;<button className="btn btn-secondary" onClick={handleFetchExistingPlan}>Fetch Data</button>
                    </>
                    {!isEmpty(errors.accuroId) && <><br /><small className='form-error-text'>{errors.accuroId}</small></>}
                  </Col>  
              </Row>
            </div>

          }

          <div className="patient-info-row">
              {calendarMode === 'patient' ? (
                <>
                  <Form.Label>Enter your name:</Form.Label>
                  <input
                    autoComplete="given-name"
                    disabled={accuroFieldsDisabled}
                    type="text"
                    value={patientInfo.name || ''}
                    onChange={(e) =>
                      setPatientInfo({ ...patientInfo, name: e.target.value })
                    }
                  />
                </>
              ) : (
                <Row>
                  <Form.Label column xs={12} xl={3}>
                    Enter patient's name:
                  </Form.Label>
                  <Col>
                    <input
                      autoComplete="given-name"
                      disabled={accuroFieldsDisabled}
                      type="text"
                      value={patientInfo.name || ''}
                      onChange={(e) =>
                        setPatientInfo({ ...patientInfo, name: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              )}
            
              {adminMode === true && calendarMode === 'patient' && (
                <>
                  <br/>
                    <Form.Label className='required'>Select Stim Start Day</Form.Label>
                      <div className='day-select-container'>
                        <DaySelector selectedDay={stimStartDay} onChange={handleChangeStimStart}/>
                      </div>

                  
                  <br/>
                  <Form.Label className='required'>Select Plan Type</Form.Label><br />
                  <div className='plan-type inline-radio'>
                        <Form.Check
                            checked={patientInfo.planType==='EF'}
                            type="radio"
                            label="Egg Freezing"
                            value={'EF'}
                            name="stradioGroup" 
                            id="stradioOption2"
                            onClick={() => setPatientInfo({ ...patientInfo, planType: 'EF'})}

                        />
                        <Form.Check
                            checked={patientInfo.planType==='IVF'}
                            type="radio"
                            label="IVF"
                            value={'IVF'}
                            name="stradioGroup" 
                            id="stradioOption1"
                            onClick={() => setPatientInfo({ ...patientInfo, planType: 'IVF'})}
                          />
                  </div>

                </>
              )}


            { calendarMode === 'clinical' && 
              <>

                <Row>
                  <Form.Label column xs={12} xl={3} className='required'> Pre-treatment Start:</Form.Label>&nbsp;
                  <Col className={'ps-1'}>
                    <DatePicker 
                    
                      placeholderText="Select Start Date"
                      selected={startDate} 
                      
                      value={startDate} 
                      onChange={(date) => setStartDate(date.setHours(0,0,0,0))} 
                      // minDate={getThreeDaysAgo() || null} // On 1-8-2023, we set 3 days ago to be the min date'
                      portalId="root"
                    />
                  </Col>

                </Row>
                <Row>
                  <Form.Label column xs={12} xl={3}  className='required'>Enter AMH (pmol/L):</Form.Label>
                  <Col>
                    <input
                      disabled={accuroFieldsDisabled} 
                      type="number"
                      value={patientInfo.amh || ''}
                      min="0"
                      max="100"
                      // onChange updates the centralized patientInfo state
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (value >= 0 && value <= 100)) {
                          setPatientInfo({ ...patientInfo, amh: value ? Math.ceil(value) : '' });
                        }
                      }}
                    />
                  </Col>
                </Row>
                {errors.amh && <><br/><small className='form-error-text'>{errors.amh}</small></>}
                <Row>
                  <Form.Label column xs={12} xl={3} className='required'>Enter Weight (lbs):</Form.Label>
                  <Col>
                    <input
                      disabled={accuroFieldsDisabled} 
                      type="number"
                      value={patientInfo.weight || ''}
                      min="0"
                      // onChange updates the centralized patientInfo state
                      onChange={(e) =>
                        setPatientInfo({ ...patientInfo, weight: Math.max(0, e.target.value) })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Form.Label column xs={12} xl={3} className='required'>Select Stim Start Day</Form.Label>
                  <Col>
                    <div className='day-select-container'>
                      <DaySelector selectedDay={stimStartDay} onChange={handleChangeStimStart}/>
                    </div>

                  </Col>
                </Row>

                <Form.Label className='required'>Select Plan Type</Form.Label><br />
                <div className='plan-type inline-radio'>
                      <Form.Check
                          checked={patientInfo.planType==='EF'}
                          type="radio"
                          label="Egg Freezing"
                          value={'EF'}
                          name="stradioGroup" 
                          id="stradioOption2"
                          onClick={() => setPatientInfo({ ...patientInfo, planType: 'EF'})}

                      />

                      <Form.Check
                          checked={patientInfo.planType==='IVF'}
                          type="radio"
                          label="IVF"
                          value={'IVF'}
                          name="stradioGroup" 
                          id="stradioOption1"
                          onClick={() => setPatientInfo({ ...patientInfo, planType: 'IVF'})}
                          />
                </div>
                {patientInfo.planType === 'IVF' && 
                <>
                  <Row>
                    <Form.Label column xs={12} xl={3} className='required'>Select Sperm Source</Form.Label>
                    <Col>
                      <div className='sperm-select-container'>
                        <Select
                          value={
                            patientInfo.spermSource
                              ? { value: patientInfo.spermSource, label: mapSpermSourceToLabel(patientInfo.spermSource) }
                              : null
                          }
                          onChange={(selection) => setPatientInfo({ ...patientInfo, spermSource: selection?.value || null })}
                          options={[
                            { value: 'Fresh ejaculate, recommend frozen backup', label: 'Partner (Fresh)' },
                            { value: 'Frozen ejaculate', label: 'Partner (Frozen)' },
                            { value: 'Frozen donor sperm, donor number to be added', label: 'Donor (Frozen)' },
                          ]}
                          placeholder="Select sperm source"
                          menuPortalTarget={document.body}
                        />
                      </div>
                    </Col>
                  </Row>
                  {patientInfo.spermSource === 'Frozen donor sperm, donor number to be added' && (
                      <>
                        <Form.Label className="required">Is the plan for reciprocal IVF?</Form.Label>
                        <div className="reciprocal-ivf inline-radio">
                          <Form.Check
                            checked={patientInfo.reciprocalIVF === false}
                            type="radio"
                            label="No"
                            value={false}
                            name="reciprocalIVF"
                            id="reciprocalIVFOptionNo"
                            onClick={() => setPatientInfo({ ...patientInfo, reciprocalIVF: false })}
                          />
                          <Form.Check
                            checked={patientInfo.reciprocalIVF === true}
                            type="radio"
                            label="Yes"
                            value={true}
                            name="reciprocalIVF"
                            id="reciprocalIVFOptionYes"
                            onClick={() => setPatientInfo({ ...patientInfo, reciprocalIVF: true })}
                          />
                        </div>
                      </>
                    )}
                </>     
                }


                <Form.Label className='required'>Will patient be on the Amoxi-clav protocol (antibiotics)?</Form.Label><br />
                <div className='antibiotics inline-radio'>
                  <Form.Check
                    checked={patientInfo.takingAntibiotics === false}
                    type="radio"
                    label="No"
                    value={false}
                    name="antibiotics"
                    id="antibioticsOptionNo"
                    // onChange={() => setPatientInfo({ ...patientInfo, takingAntibiotics: false })}
                    onClick={() => setPatientInfo({ ...patientInfo, takingAntibiotics: false })}
                  />
                  <Form.Check
                    checked={patientInfo.takingAntibiotics === true}
                    type="radio"
                    label="Yes"
                    value={true}
                    name="antibiotics"
                    id="antibioticsOptionYes"
                    // onChange={() => setPatientInfo({ ...patientInfo, takingAntibiotics: true })}
                    onClick={() => setPatientInfo({ ...patientInfo, takingAntibiotics: true })}
                  />
                </div>
                {/* <Form.Label className='required'>Should patient start Eltroxin with Pretreatment?</Form.Label><br />
                <div className='eltroxin-pre inline-radio'>
                      <Form.Check
                          checked={eltroxinInPretreatment===false}
                          type="radio"
                          label="No"
                          value={false}
                          name="eltroxinradioGroup" 
                          id="eltroxinradioOption2"
                          onChange={()=>setEltroxinInPretreatment(false)}
                          onClick={() => setPatientInfo({ ...patientInfo, eltroxinInPretreatment: false})}

                      />

                      <Form.Check
                          checked={eltroxinInPretreatment===true}
                          type="radio"
                          label="Yes"
                          value={true}
                          name="eltroxinradioGroup" 
                          id="eltroxinradioOption1"
                          onChange={()=>setEltroxinInPretreatment(true)}
                           onClick={() => setPatientInfo({ ...patientInfo, eltroxinInPretreatment: true})}
                          
                          />
                  </div> */}


                <Form.Label className='required'>Please note if patient is already taking any of the below medications:</Form.Label>
                <div className="medication-options">
                  <Form.Check
                    id="eltroxin-check"
                    type="checkbox"
                    label="Eltroxin (or other thyroid medication)"
                    value="Eltroxin"
                    checked={patientInfo.existingPatientMedications?.includes("Eltroxin")}
                    onClick={handleMedicationChange}
                  />
                  <Form.Check
                    id="metformin-check"
                    type="checkbox"
                    label="Metformin"
                    value="Metformin"
                    checked={patientInfo.existingPatientMedications?.includes("Metformin")}
                    onClick={handleMedicationChange}
                  />
                  <Form.Check
                    id="none-check"
                    type="checkbox"
                    label="None of the above"
                    value="None"
                    checked={patientInfo.existingPatientMedications?.includes("None")}
                    onClick={handleMedicationChange}
                  />
                </div>

              </>
            }


          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

export default CalendarInput;
