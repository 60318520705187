import getPergoverisDose from "./temp_pergoveris";
import { getProtocolFromAMH, isEmpty } from "./common";

import moment from 'moment';
import { extendMoment } from 'moment-range';
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import MedicationList from "../Components/Calendar/MedicationList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const momentRange = extendMoment(moment);

function sentenceBuilder(prefix, medList) {
  // Filter out any falsy values (e.g., false, null) from the medList
  const meds = medList.filter(Boolean);
  if (meds.length === 0) {
    // Return an empty string if there are no medications
    return '';
  }

  let sentence = prefix + ' ';
  if (meds.length === 1) {
    sentence += meds[0] + '. ';
  } else {
    // Join all meds with commas, and add 'and' before the last med
    const lastMed = meds.pop();
    sentence += meds.join(', ') + ', and ' + lastMed + '. ';
  }
  return sentence;
}

const filterExcludedMeds = (meds,existingPatientMedications ) => meds.filter(med => med && !existingPatientMedications.includes(med.med));

const medsForDayAndNight = (dayMeds, nightMeds, extraInfo, existingPatientMedications=[]) => {

  return {
    dayMeds: <MedicationList meds={filterExcludedMeds(dayMeds, existingPatientMedications)} icon={<FontAwesomeIcon icon={faSun} size="md" />} /> ,
    nightMeds: <MedicationList meds={filterExcludedMeds(nightMeds, existingPatientMedications)} icon={<FontAwesomeIcon icon={faMoon} size="md" />} /> ,
    extraInfo: !isEmpty(extraInfo) ? extraInfo : []
  };
};
// 7-15-2024 : we introduce sorting by date, so that dynamic appointment dates can appear in the correct spot
const sortItemsByDate = items => {
  return items
      .filter(item => item.sortDate !== null)
      .sort((a, b) => a.sortDate.diff(b.sortDate));
};

// 7-15-2024 : we introduce sorting by date, so that dynamic appointment dates can appear in the correct spot
const generateDateItem = (date, content, format = "MMMM Do") => {
  const displayDate = date.format(format);
  return { sortDate: date, date: displayDate, content };
};

export const getPhase1Meds = (event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, medicationList, existingPatientMedications) => {
  const order = dayOfEvent.extendedProps.order;
  const totalNumberDays = momentEndDate.diff(momentStartDate.clone().subtract(1, 'days'), 'days'); // full calendar end dates are exclusive, so reduce them by 1 for moment to accurately get the last week
  const isLastDay = order === totalNumberDays-1;
  const isEighthToLastDay = order === totalNumberDays-8;
  const isWithinLast8Days = totalNumberDays - order <= 8;
  const extraInfo = dayOfEvent.extendedProps.extraInfo || [];

  if (isLastDay) {
    return medsForDayAndNight(
      [{ med: 'Lupin-Estradiol', medicationClassName: 'mark' }, { med: 'Prometrium', medicationClassName: 'mark' }],
      [{ med: 'Lupin-Estradiol', medicationClassName: 'mark' }, { med: 'Prometrium', medicationClassName: 'mark' }],
      extraInfo, existingPatientMedications
    );
  } else if (isEighthToLastDay) {
    return medsForDayAndNight(
      [{ med: 'Lupin-Estradiol' }, { med: 'Prometrium', medicationClassName: 'mark' }],
      [{ med: 'Lupin-Estradiol' }, { med: 'Prometrium', medicationClassName: 'mark' }],
      extraInfo, existingPatientMedications
    );
  } else if (isWithinLast8Days) {
    return medsForDayAndNight(
      [{ med: 'Lupin-Estradiol' }, { med: 'Prometrium' }],
      [{ med: 'Lupin-Estradiol' }, { med: 'Prometrium' }],
      extraInfo, existingPatientMedications
    );
  } else {
    return medsForDayAndNight(
      order === 1 ? [] : [{ med: 'Lupin-Estradiol' }],
      [{ med: 'Lupin-Estradiol' }],
      extraInfo, existingPatientMedications
    );
  }
}
  
export async function getPhase1Desc(event, amh, weight, cycleOrientation, monitoringAppointments, medicationList, existingPatientMedications = []) {
  const startDate = moment(event.start).clone();
  const endDate = moment(event.end).clone();
  
  const day1 = startDate.clone();
  const day2 = startDate.clone().add(1, 'days');
  const eighthBeforeLastDay = endDate.clone().subtract(8, 'days');
  const endDateFormatted = endDate.clone().subtract(1, 'days');
  const cycleOrientationDate = !isEmpty(cycleOrientation) ? moment(cycleOrientation.start).clone() : null;
  
  // Medication dosages
  const prometriumDosage = medicationList['Prometrium']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
  const lupinEstradiolDosage = medicationList['Estradiol']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;

  const items = [
      !existingPatientMedications.includes('Estradiol') && generateDateItem(day1, `You will be instructed to start Lupin-Estradiol ${lupinEstradiolDosage} (1 tablet) inserted vaginally in the evening.`),
      !existingPatientMedications.includes('Estradiol') && generateDateItem(day2, `Increase dose of Lupin-Estradiol ${lupinEstradiolDosage} (1 tablet) vaginally to TWICE daily (morning and evening) for a total of 2 tablets/day. Continue taking this medication daily with no changes, as outlined in calendar.`),
      !existingPatientMedications.includes('Prometrium') && generateDateItem(eighthBeforeLastDay, (<>Add Prometrium {prometriumDosage} <br /><b><mark>We recommend you set a reminder for this.</mark></b></>)),

      [!existingPatientMedications.includes('Estradiol') && 'Lupin-Estradiol', !existingPatientMedications.includes('Prometrium') && 'Prometrium'].filter(Boolean).length > 0 &&
        generateDateItem(endDateFormatted, `Take last dose of ${[!existingPatientMedications.includes('Estradiol') && 'Lupin-Estradiol', !existingPatientMedications.includes('Prometrium') && 'Prometrium'].filter(Boolean).join(' and ')} as scheduled in evening. This is the last day of pre-treatment medications.`),

      !isEmpty(cycleOrientationDate) && generateDateItem(cycleOrientationDate, 'Log in to zoom for your cycle orientation appointment using the link emailed by your nurse.')
  ].filter(Boolean); // Filter out any false (omitted) entries

  const sortedItems = sortItemsByDate(items);

  return (
      <ul>
          {sortedItems.map((item, index) => (
              <li key={index}><b>{item.date}: </b>{item.content}</li>
          ))}
          <li>Refer to medication PDF for more information on these medications, including side effects</li>
          
      </ul>
  );
}

// 10-3-2024: Is the provided day (order) the first appearance of Eltroxin? 
// This is needed, because Eltroxin begins on Day 2 for Monday stim starts, and Day 1 for Sat/Sun stim starts. 
function showEltroxinStart(stimStartDay, order) {
  switch(stimStartDay.stimStartDayOfWeek){
    case 'Monday': 
      return order === 2;
    case 'Sunday':
    case 'Saturday':
      return order === 1;
  }
}


export const getPhase2Meds = (event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, medicationList, stimStartDay, existingPatientMedications) => {
  const orgalutranDoses = medicationList['Orgalutran']?.['parsedDosage'] || 'Unknown';
  const pergoverisDose = medicationList['Pergoveris']?.['parsedDosage'] || 'Unknown';
  const order = dayOfEvent.extendedProps.order;
  const extraInfo = dayOfEvent.extendedProps.extraInfo || [];
  
  if (amh < 12) {
    switch (order) {
      case 1:
        return medsForDayAndNight(
          [showEltroxinStart(stimStartDay, order) ? { med: 'Eltroxin'} : undefined], // shows eltroxin on day 1 only for sat/sun starts
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      case 2:
        return medsForDayAndNight(
          [{ med: 'Eltroxin'}], // eltroxin always present on day 2
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      case 3:
      case 4:
      case 5:
      case 6:
        return medsForDayAndNight(
          [{ med: 'Eltroxin' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      case 7:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: `(${orgalutranDoses['d7']})`, medicationClassName: 'bold mark' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      case 8:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: `(${orgalutranDoses['d8']})`, medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      default:
        break;
    }
  } else if (amh >= 12 && amh < 24) {
    switch (order) {
      case 1:
        return medsForDayAndNight(
          [showEltroxinStart(stimStartDay, order) ? { med: 'Eltroxin'} : undefined], // shows eltroxin on day 1 only for sat/sun starts
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
          case 2:
        return medsForDayAndNight(
          [{ med: 'Eltroxin'}], // eltroxin always present on day 2
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], [], existingPatientMedications);
          case 3:
      case 4:
        return medsForDayAndNight(
          [{ med: 'Eltroxin' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
          case 5:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: `(${orgalutranDoses['d5']})`, medicationClassName: 'bold mark' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
          case 6:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: `(${orgalutranDoses['d6']})`, medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
          case 7:
      case 8:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: `(${orgalutranDoses['d7']})`, medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
          default:
        break;
    }
  } else if (amh >= 24) {
    switch (order) {
      case 1:
        return medsForDayAndNight(
          [showEltroxinStart(stimStartDay, order) ? { med: 'Eltroxin'} : undefined], // shows eltroxin on day 1 only for sat/sun starts
          [
          { med: 'Dexamethasone' },
          { med: 'Metformin' },
          { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
        ], extraInfo, existingPatientMedications);
      case 2:
        return medsForDayAndNight(
          [{ med: 'Eltroxin'}], // eltroxin always present on day 2
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      case 3:
      case 4:
        return medsForDayAndNight(
          [{ med: 'Eltroxin' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      case 5:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: `(${orgalutranDoses['d5']})`, medicationClassName: 'bold mark' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      case 6:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: `(${orgalutranDoses['d6']})`, medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
      case 7:
      case 8:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: `(${orgalutranDoses['d7']})`, medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ], extraInfo, existingPatientMedications);
          default:
        break;
    }
  }
};


  export async function getPhase2Desc(event, amh, weight, monitoringAppointments=[], stimStartDay, medicationList, existingPatientMedications ) {
    const orgalutranDoses = medicationList['Orgalutran']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const pergoverisDose = medicationList['Pergoveris']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const dexamethasoneDose = medicationList['Dexamethasone']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const clomipheneDose = medicationList['Clomiphene']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const eltroxinDose = medicationList['Eltroxin']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const metforminDose = medicationList['Metformin']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;

    const startDate = moment(event.start).clone();
  
    const formatDay = (dayOffset) => startDate.clone().add(dayOffset, 'days').format('MMMM Do');
    const monitoringDates = monitoringAppointments.map(app => moment(app.start).format('MMMM Do'));
  
    const day1 = formatDay(0);
    const day2 = formatDay(1);
    const day3 = formatDay(2);
    const day4 = formatDay(3);
    const day5 = formatDay(4);
    const day6 = formatDay(5);
    const day7 = formatDay(6);
    const day8 = formatDay(7);
    
    const shouldShowMonitoring = (day, text='Come to clinic for monitoring as scheduled. Pending call from nurse: ') => monitoringDates.includes(day) ? <u>{text}</u> : null;
    // 10-3-2024: Eltroxin begins on Day 2 for Mon starts, and Day 1 for Sat/Sun starts. showEltroxinStart applies this logic.  
    if (amh < 12) {
      return (
        <ul>
          <li><b>{day1}</b>:&nbsp;
            {showEltroxinStart(stimStartDay, 1) && sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin (Synthroid) ${eltroxinDose} orally once daily`])}
            {shouldShowMonitoring(day1, 'Come to clinic for monitoring as scheduled.')}
            {sentenceBuilder("At the same time in the evening (PM), start", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose} orally once daily`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose} orally once daily`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose} injected into belly`
            ])}
          </li>
          <li><b>{day2}</b>:&nbsp;
            {showEltroxinStart(stimStartDay, 2)? sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin (Synthroid) ${eltroxinDose} orally once daily`]): sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day2)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day3}</b>:&nbsp;
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day3)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>

          <li><b>{day4}</b>:&nbsp;
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day4)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>

          <li><b>{day5}</b>:&nbsp;
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day5)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>

          <li><b>{day6}</b>:&nbsp;
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day6)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>

          <li><b>{day7}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d7']} (full dose), and inject into belly`])}
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day7)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>

          <li><b>{day8}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), continue", [!existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d8']} (full dose), and inject into belly before monitoring`])}
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day8)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
        </ul>
      );
    } else if (amh >= 12 && amh < 24) {
      return (
        <ul>
          <li><b>{day1}</b>:&nbsp;
            {showEltroxinStart(stimStartDay, 1) && sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin (Synthroid) ${eltroxinDose} orally once daily`])}
            {shouldShowMonitoring(day1, 'Come to clinic for monitoring as scheduled.')}
            {sentenceBuilder("At the same time in the evening (PM), start", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose} orally once daily`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose} injected into belly`
            ])}
          </li>
          <li><b>{day2}</b>:&nbsp;
            {showEltroxinStart(stimStartDay, 2)
              ? sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin (Synthroid) ${eltroxinDose} orally once daily`])
              : sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day2)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day3}</b>:&nbsp;
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day3)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day4}</b>:&nbsp;
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day4)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day5}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d5']} (half dose), and inject into belly before monitoring`])}
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day5)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day6}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d6']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            {shouldShowMonitoring(day6)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day7}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d7']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            {shouldShowMonitoring(day7)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day8}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d8']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            {shouldShowMonitoring(day8)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
        </ul>
      );
    } else if (amh >= 24) {
      return (
        <ul>
          <li><b>{day1}</b>:&nbsp;
            {showEltroxinStart(stimStartDay, 1) && sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin (Synthroid) ${eltroxinDose} orally once daily`])}
            {shouldShowMonitoring(day1, 'Come to clinic for monitoring as scheduled.')}
            {sentenceBuilder("At the same time in the evening (PM), start", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose} orally once daily`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose} orally once daily`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose} injected into belly`
            ])}
          </li>
          <li><b>{day2}</b>:&nbsp;
            {showEltroxinStart(stimStartDay, 2)
              ? sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin (Synthroid) ${eltroxinDose} orally once daily`])
              : sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day2)}
            {sentenceBuilder("In the evening (PM), continue taking", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day3}</b>:&nbsp;
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day3)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day4}</b>:&nbsp;
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day4)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day5}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), start", [!existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d5']} (half dose), and inject into belly before monitoring`])}
            {sentenceBuilder("In the morning (AM), continue", [!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day5)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day6}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d6']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            {shouldShowMonitoring(day6)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day7}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d7']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            {shouldShowMonitoring(day7)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
          <li><b>{day8}</b>:&nbsp;
            {sentenceBuilder("At the same time in the morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d8']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            {shouldShowMonitoring(day8)}
            {sentenceBuilder("In the evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
          </li>
        </ul>
      );
    }
  }
  
  
  export const getPhase3Meds = (event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, takingAntibiotics, medicationList, existingPatientMedications) => {
    const orgalutranDoses = medicationList['Orgalutran']?.['parsedDosage'] || 'Unknown';
    const pergoverisDose = medicationList['Pergoveris']?.['parsedDosage'] || 'Unknown';
    const order = dayOfEvent.extendedProps.order;
    const extraInfo = dayOfEvent.extendedProps.extraInfo || [];

    if (amh < 12) {
      switch (order) {
        case 1:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: `(${orgalutranDoses['d9']})`, medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Clomiphene' },
              { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
            ], extraInfo, existingPatientMedications);
        case 2:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: `(${orgalutranDoses['d10']})`, medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Clomiphene' },
              { med: 'Pergoveris', dosage: `(${pergoverisDose})` },
              { med: 'If Triggered: Ovidrel+Decapeptyl', medicationClassName: 'bold mark' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav', medicationClassName: 'bold' }] : [])
            ], extraInfo, existingPatientMedications);
        case 3:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [...extraInfo, 'Post-trigger bloodwork'], existingPatientMedications);
        case 4:
        case 5:
        case 6:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ], extraInfo, existingPatientMedications);
        default:
          break;
      }
    } else if (amh >= 12 && amh < 24) {
      switch (order) {
        case 1:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: `(${orgalutranDoses['d9']})`, medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
            ], extraInfo, existingPatientMedications);
        case 2:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: `(${orgalutranDoses['d10']})`, medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'If Triggered: Ovidrel+Decapeptyl', medicationClassName: 'bold mark' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav', medicationClassName: 'bold' }] : [])
            ], extraInfo, existingPatientMedications);
        case 3:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [...extraInfo, 'Post-trigger bloodwork'], existingPatientMedications);
        case 4:
        case 5:
        case 6:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ], extraInfo, existingPatientMedications);
            default:
          break;
      }
    } else if (amh >= 24) {
      switch (order) {
        case 1:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: `(${orgalutranDoses['d9']})`, medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Metformin' },
              { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
            ], extraInfo, existingPatientMedications);
        case 2:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: `(${orgalutranDoses['d10']})`, medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Metformin' },
              { med: 'If Triggered: Ovidrel+Decapeptyl', medicationClassName: 'bold mark' },
              { med: 'Cabergoline' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav', medicationClassName: 'bold' }] : [])
            ], extraInfo, existingPatientMedications);
        case 3:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Cabergoline' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ],
            ['Post-trigger bloodwork'], existingPatientMedications);
        case 4:
        case 5:
        case 6:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Cabergoline' },
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ], extraInfo, existingPatientMedications);
            default:
          break;
      }
    }
  };
    
  export async function getPhase3Desc (event, amh=26, weight, takingAntibiotics=false, monitoringAppointments=[], medicationList, existingPatientMedications) {
    const orgalutranDoses = medicationList['Orgalutran']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const pergoverisDose = medicationList['Pergoveris']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const dexamethasoneDose = medicationList['Dexamethasone']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const clomipheneDose = medicationList['Clomiphene']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const eltroxinDose = medicationList['Eltroxin']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const metforminDose = medicationList['Metformin']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const decapeptylDose = medicationList['Decapeptyl']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const letrozoleDose = medicationList['Letrozole']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const cabergolineDose = medicationList['Cabergoline']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const amoxiclavDose = medicationList['APO-Amoxi Clav']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;

    const startDate = moment(event.start).clone();
    const endDate = moment(event.end).clone();

    const formatDay = (dayOffset) => startDate.clone().add(dayOffset, 'days').format('MMMM Do');
    const monitoringDates = monitoringAppointments.map(app => moment(app.start).format('MMMM Do'));

    const day1 = formatDay(0);
    const day2 = formatDay(1);
    const day3 = formatDay(2);
    const day4 = formatDay(3);
    const day5 = formatDay(4);
    const day6 = formatDay(5);
    const day7 = formatDay(6);
    const day8 = formatDay(7);
    const shouldShowMonitoring = (day, text='Come to clinic for monitoring as scheduled. Pending call from nurse: ') => monitoringDates.includes(day) ? <u>{text}</u> : null;

    if (amh < 12) {
      return (
        <ul>
          <li><b>{day1}:</b>&nbsp;
            {sentenceBuilder("At same time in morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d9']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            {shouldShowMonitoring(day1)}
            {sentenceBuilder("In evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
            This is earliest possible trigger date.
          </li>
          <li><b>{day2} (if triggered):</b>&nbsp;
            {sentenceBuilder("At same time in morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d10']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            <u>Come to clinic for monitoring as scheduled.</u> Pending call from nurse, if triggered, you will receive specific instructions for ovulation trigger. This includes:&nbsp;
            {sentenceBuilder("In evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Clomiphene') && `Clomiphene ${clomipheneDose}`
            ])}
            Inject Ovidrel into belly 35 hours before egg retrieval, and Decapeptyl {decapeptylDose} x 2 syringes injected into belly 34 hours before egg retrieval.
          </li>
          {takingAntibiotics === true && (
            <li>On the evening of your trigger, take 1 Amoxi-clav {amoxiclavDose} tablet orally. Continue taking 1 Amoxi-Clavulanate in the AM and PM for the next 6 days.</li>
          )}
          <li><b>{day3} (if triggered on {day2}):</b>&nbsp;
            <u>You will be scheduled for blood work only this morning at the clinic.</u> You will not receive a call from nurse on this day.
          </li>
          <li><b>{day4} (if triggered on {day2}):</b>&nbsp;
            <u>You will be scheduled for egg retrieval this morning at the clinic.</u>&nbsp;
            {sentenceBuilder("In evening (PM), start", [
              !existingPatientMedications.includes('Letrozole') && `Letrozole ${letrozoleDose} for 5 days in a row`
            ])}
          </li>
        </ul>
      );
    } else if (amh >= 12 && amh < 24) {
      return (
        <ul>
          <li><b>{day1}:</b>&nbsp;
            {sentenceBuilder("At same time in morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d9']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            {shouldShowMonitoring(day1)}
            {sentenceBuilder("In evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
            This is earliest possible trigger date.
          </li>
          <li><b>{day2} (if triggered):</b>&nbsp;
            {sentenceBuilder("At same time in morning (AM), continue", [
              !existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d10']}`,
              !existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`
            ])}
            <u>Come to clinic for monitoring as scheduled.</u> Pending call from nurse, if triggered, you will receive specific instructions for ovulation trigger. This includes:&nbsp;
            {sentenceBuilder("In evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`
            ])}
            Inject Ovidrel into belly 35 hours before egg retrieval, and Decapeptyl {decapeptylDose} x 2 syringes injected into belly 34 hours before egg retrieval.
          </li>
          {takingAntibiotics === true && (
            <li>On the evening of your trigger, take 1 Amoxi-clav {amoxiclavDose} tablet orally. Continue taking 1 Amoxi-Clavulanate in the AM and PM for the next 6 days.</li>
          )}
          <li><b>{day3} (if triggered on {day2}):</b>&nbsp;
            <u>You will be scheduled for blood work only this morning at the clinic.</u> You will not receive a call from nurse on this day.
          </li>
          <li><b>{day4} (if triggered on {day2}):</b>&nbsp;
            <u>You will be scheduled for egg retrieval this morning at the clinic.</u>&nbsp;
            {sentenceBuilder("In evening (PM), start", [
              !existingPatientMedications.includes('Letrozole') && `Letrozole ${letrozoleDose} for 5 days in a row`
            ])}
          </li>
        </ul>
      );
    } else if (amh >= 24) {
      return (
        <ul>
          <li><b>{day1}:</b>&nbsp;
            {sentenceBuilder("At same time in morning (AM), continue", [!existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d9']}`,!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            {shouldShowMonitoring(day1)}
            {sentenceBuilder("In evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`,
              !existingPatientMedications.includes('Pergoveris') && `Pergoveris ${pergoverisDose}`
            ])}
            This is earliest possible trigger date.
          </li>
          <li><b>{day2} (if triggered):</b>&nbsp;
            {sentenceBuilder("At same time in morning (AM), continue", [!existingPatientMedications.includes('Orgalutran') && `Orgalutran ${orgalutranDoses['d10']}`,!existingPatientMedications.includes('Eltroxin') && `Eltroxin ${eltroxinDose}`])}
            <u>Come to clinic for monitoring as scheduled.</u> Pending call from nurse, if triggered, you will receive specific instructions for ovulation trigger. This includes:&nbsp;
            {sentenceBuilder("In evening (PM), continue", [
              !existingPatientMedications.includes('Dexamethasone') && `Dexamethasone ${dexamethasoneDose}`,
              !existingPatientMedications.includes('Metformin') && `Metformin ${metforminDose}`
            ])}
            Start Cabergoline {cabergolineDose} orally for 7 days in a row, along with Ovidrel injected into belly 35 hours before egg retrieval, and Decapeptyl {decapeptylDose} x 2 syringes injected into belly 34 hours before egg retrieval.
          </li>
          {takingAntibiotics === true && (<li>On the evening of your trigger, take 1 Amoxi-clav {amoxiclavDose} tablet orally. Continue taking 1 Amoxi-Clavulanate in the AM and PM for the next 6 days.</li>)}
          <li><b>{day3} (if triggered on {day2}):</b>&nbsp;
            <u>You will be scheduled for blood work only this morning at the clinic.</u> You will not receive a call from nurse on this day.&nbsp;
            {sentenceBuilder("In evening (PM), continue", [!existingPatientMedications.includes('Cabergoline') && `Cabergoline ${cabergolineDose} daily`])}
          </li>
          <li><b>{day4} (if triggered on {day2}):</b>&nbsp;
            <u>You will be scheduled for egg retrieval this morning at the clinic.</u>&nbsp;
            {sentenceBuilder("In evening (PM), continue", [!existingPatientMedications.includes('Cabergoline') && `Cabergoline ${cabergolineDose} daily`,!existingPatientMedications.includes('Letrozole') && `Letrozole ${letrozoleDose} for 5 days in a row`])}
          </li>
        </ul>
      );
    }
      }


  export const getPhase4Meds = (event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, takingAntibiotics, medicationList, existingPatientMedications) => {
    const order = dayOfEvent.extendedProps.order;
    const extraInfo = dayOfEvent.extendedProps.extraInfo || [];

    if (amh < 12) {
      switch (order) {
        case 1:
        case 2:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ], extraInfo, existingPatientMedications);
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        default:
          break;
      }
    } else if (amh >= 12 && amh < 24) {
      switch (order) {
        case 1:
        case 2:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ], extraInfo, existingPatientMedications);
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        default:
          break;
      }
    } else if (amh >= 24) {
      switch (order) {
        case 1:
        case 2:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Cabergoline' },
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ], extraInfo, existingPatientMedications);
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        default:
          break;
      }
    }
  };
    

  export async function getPhase4Desc  (event, amh, weight, monitoringAppointments, medicationList, existingPatientMedications) {
    const startDate = moment(event.start).clone();
    const endDate = moment(event.end).clone();

    const day1to2 = startDate.clone().format("MMMM Do") + " to " + startDate.clone().add(1, 'days').format("MMMM Do");
    const dayMinus2to2 = startDate.clone().subtract(2, 'days').format("MMMM Do") + " to " + startDate.clone().add(1, 'days').format("MMMM Do")
    const estimatedTrigger = startDate.clone().subtract(5, 'days').format("MMMM Do")
    const startToEndDateFormatted = startDate.clone().format("MMMM Do") + " to " + endDate.clone().subtract(1, 'days').format("MMMM Do");
    const letrozoleDose = medicationList['Letrozole']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    const cabergolineDose = medicationList['Cabergoline']?.['parsedDosage'] || <b class='highlighted'>(Unknown Dose)</b>;
    

    if (amh < 12) {
      return (
        <ul>
          <li><b>{dayMinus2to2} (if triggered on {estimatedTrigger}):</b>&nbsp;{sentenceBuilder("Continue taking", [!existingPatientMedications.includes('Letrozole') && `Letrozole ${letrozoleDose} following the egg retrieval procedure`])} The medication should be taken until it's done.</li>
          <li><b>{startToEndDateFormatted}:</b>&nbsp;Please stay close to the clinic in the event you need to be seen in person in the week following your egg retrieval procedure.</li>
        </ul>
      );
    } else if (amh >= 12 && amh < 24) {
      return (
        <ul>
          <li><b>{dayMinus2to2} (if triggered on {estimatedTrigger}):</b>&nbsp;{sentenceBuilder("Continue taking", [!existingPatientMedications.includes('Letrozole') && `Letrozole ${letrozoleDose} following the egg retrieval procedure`])} The medication should be taken until it's done.</li>
          <li><b>{startToEndDateFormatted}:</b>&nbsp;Please stay close to the clinic in the event you need to be seen in person in the week following your egg retrieval procedure.</li>
        </ul>
      );
    } else if (amh >= 24) {
      return (
        <ul>
          <li><b>{dayMinus2to2} (if triggered on {estimatedTrigger}):</b>&nbsp;{sentenceBuilder("Continue both", [!existingPatientMedications.includes('Cabergoline') && `Cabergoline ${cabergolineDose} daily`,!existingPatientMedications.includes('Letrozole') && `Letrozole ${letrozoleDose} following the egg retrieval procedure`])} Both medications should be taken until they're done.</li>
          <li><b>{startToEndDateFormatted}:</b>&nbsp;Please stay close to the clinic in the event you need to be seen in person in the week following your egg retrieval procedure.</li>
        </ul>
      );
    }
    
}

function getMonitoringApptMatches(noMedsEvent, monitoringAppointments, stimStartDay) {

  const noMedsStart = moment(noMedsEvent.start);
  const noMedsEnd = moment(noMedsEvent.end).clone().subtract('days', 1);
  const noMedsRange = momentRange.range(noMedsStart, noMedsEnd);

  const overlappingAppointments = monitoringAppointments.filter(appointment => {
    const appointmentStart = moment(appointment.start);
    return noMedsRange.contains(appointmentStart);
  });

  
  return overlappingAppointments.map(appointment => (
    <li key={appointment.start}>
      <b>{moment(appointment.start).clone().format("MMMM Do")}</b>: <u>Come to clinic for monitoring as scheduled. This assessment is to clear you to start on {stimStartDay.stimStartDayOfWeek}</u>
    </li>
  ));
}

export async function getNoMedsDesc(event, amh, weight, monitoringAppointments, stimStartDay) {

  return (
    <ul>
      {getMonitoringApptMatches(event, monitoringAppointments, stimStartDay)}
    </ul>
  );
}
