import React, { useEffect, useState, useRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useKeyDown } from '../../hooks/useKeyDown';

// Import your form components
import PatientInfoForm from './PatientInfoForm';
import {ClinicAppointmentInputs, PatientDay1Input, PatientDay1Wrapper} from './PatientDay1Input';
import PatientBlackoutForm from './PatientBlackoutForm';
import { getDateAfterDays, getDefaultMonitoringAppointments, isEmpty } from '../../js/common';
import DraggablePopup from '../common/DraggablePopup';
import moment from 'moment';


const formatMonitoringAppointmentsFromEventList = (events) => {
  const monitoringAppointments = events.reduce((acc, event) => {
    if (event.id.startsWith('clinic-visit-monitoring-')){
      acc[event.id] = { date: moment(event.start).toDate(), time: event.extendedProps?.time};
    }
    return acc;
  }, {});
  return monitoringAppointments;
}
function CalendarForm({
  calendarKey,
  onFormSubmit,
  calendarConfig,
  setCalendarConfig,
  calendarMode,
  events, // used for generating default monitoring appts after calendar is generated
  clearCalendar,
  onEventListPatch,
  adminMode
}) {


  const [startDate, setStartDate] = useState(calendarConfig.startDate);
  const [stimStartDay, setStimStartDay] = useState(calendarConfig.stimStartDay || {stimStartDayOfWeek: 'Saturday', stimOffset: -2, label:'Saturday' , value:'Saturday' ,});
  const [cycleReviewDatetime, setCycleReviewDatetime] = useState(calendarConfig.cycleReviewDatetime);
  const [monitoringVisits, setMonitoringVisits] = useState(calendarConfig.monitoringVisits || {});
  const [patientBlackouts, setPatientBlackouts] = useState(calendarConfig.patientBlackouts || []);
  const [patientInfo, setPatientInfo] = useState(calendarConfig.patientInfo || {});
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState({});

  const handleNext = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);
  const handleBreadcrumbClick = (index) => setStep(index);

  let breadcrumbs = ['Name', 'My Day 1', 'Away Dates'];
  if (calendarMode==='clinical'){
    breadcrumbs = ['Patient Info'];
  }
  const inputsAreInvalid = () => {
    const newErrors = {};
    if (isEmpty(startDate)){
      newErrors['startDate'] = true;
    }
    if (calendarMode==='clinical'){

      if (isEmpty(patientInfo.amh)){
        newErrors['amh'] = true;
      }
      if (isEmpty(patientInfo.weight)){
        newErrors['weight'] = true;
      }
      if (isEmpty(patientInfo.takingAntibiotics)){
        newErrors['takingAntibiotics'] = true;
      }
      if (isEmpty(patientInfo.stimType)){
        newErrors['stimType'] = true;
      }
  
    } else if (calendarMode === 'patient'){
      if (patientBlackouts === undefined){
        newErrors['patientBlackouts'] =true
      }
    }
    return !isEmpty(newErrors);

  }

  const onSubmit = () => {
    onFormSubmit({startDate, cycleReviewDatetime, monitoringVisits, patientBlackouts, patientInfo, calendarConfig, calendarKey, stimStartDay})
  }

  // move to next form page when enter is clicked
  useKeyDown(() => {
    if (step !== breadcrumbs.length-1) {
      handleNext();
    }
  }, ["Enter"]);  

  // reset the values with the latest ones if they are updated
  useEffect(() => {
        
    setStartDate(calendarConfig.startDate);
    setCycleReviewDatetime(calendarConfig.cycleReviewDatetime);
    
    const monitoringAppointments = formatMonitoringAppointmentsFromEventList(events);
    setMonitoringVisits( isEmpty(monitoringAppointments) ? getDefaultMonitoringAppointments(events, stimStartDay) : monitoringAppointments);
    setPatientBlackouts(calendarConfig.patientBlackouts)
    setPatientInfo(calendarConfig.patientInfo || {})
  }, [calendarKey]);

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     console.log('do validate')
  //   }
  // }

  return (
    <div className="calendar-wizard-wrapper">
      <div className="breadcrumb-container">
            {breadcrumbs.map((breadcrumb, index) => (
              <div
                key={index}
                className={`btn breadcrumb ${index === step ? 'active' : ''}`}
                onClick={() => handleBreadcrumbClick(index)}
              >
                {breadcrumb}
              </div>
            ))}
        </div>

        <TransitionGroup className="slide-group">
          <CSSTransition
              timeout={300}
              classNames="slide"
              key={step}
          >
          <div className="calendar-form patient-form">
            {step === 0 && <PatientInfoForm setPatientInfo={setPatientInfo} patientInfo={patientInfo} calendarMode={calendarMode} setStartDate={setStartDate} startDate={startDate} stimStartDay={stimStartDay} setStimStartDay={setStimStartDay} adminMode={adminMode}/>}
            {step === 1 && calendarMode === 'patient' && <PatientDay1Wrapper setStartDate={setStartDate} startDate={startDate} onFormSubmit={onFormSubmit} calendarMode={calendarMode} cycleReviewDatetime={cycleReviewDatetime} setCycleReviewDatetime={setCycleReviewDatetime} monitoringVisits={monitoringVisits} setMonitoringVisits={setMonitoringVisits} />}
            {step === 2 && calendarMode === 'patient' && <PatientBlackoutForm patientBlackouts={patientBlackouts} day1StartDate={startDate} setPatientBlackouts={setPatientBlackouts} calendarMode={calendarMode} />}
          </div>
          </CSSTransition>
        </TransitionGroup>
      <div className="form-btns">
          <button onClick={handleBack} className="btn btn-primary back-button" disabled={step === 0}>
            <FontAwesomeIcon icon={faChevronCircleLeft} /> Back
          </button>
          
          <div className='forward-btns'>
            {calendarMode === 'clinical' && <button onClick={clearCalendar} className="btn btn-danger next-button"> <FontAwesomeIcon icon={faTrashAlt} /> Clear Inputs </button>}
            <button onClick={handleNext} type="submit" className="btn btn-primary next-button" disabled={step === breadcrumbs.length-1}>
              <FontAwesomeIcon icon={faChevronCircleRight} /> Next 
            </button>
            <button  type="submit" onClick={onSubmit} className="btn btn-primary" disabled={inputsAreInvalid()} >
              Submit 
            </button>
          </div>

        </div>
        {calendarMode === 'clinical' && !isEmpty(events) &&
          <DraggablePopup title="Appointment Dates"> 
            <div className='popup-date-form-container'>
              <ClinicAppointmentInputs
                setStartDate={setStartDate}
                startDate={startDate}
                cycleReviewDatetime={cycleReviewDatetime}
                setCycleReviewDatetime={setCycleReviewDatetime}
                monitoringVisits={monitoringVisits}
                setMonitoringVisits={setMonitoringVisits}
              />
              <br/>
              <button onClick={() => {
                const eventsToEdit = {...monitoringVisits}
                if (!isEmpty(cycleReviewDatetime)){
                  eventsToEdit['cycle-review-datetime'] = cycleReviewDatetime;
                  setCalendarConfig({...calendarConfig, cycleReviewDatetime })
                }
                onEventListPatch(eventsToEdit);
              }}
                
                className="btn btn-primary" >
                Submit
              </button>
            </div>
          </DraggablePopup>
      }
    </div>

        
  );
}

export default CalendarForm;
