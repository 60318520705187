import { fetchPatientDemographics, getAccessToken, getLabResults } from './api/vennabackend';
import { isEmpty } from './common';

export const convertLabResultsToStandardUnits = (results) => {
  let heightInches = null;
  let weightPounds = null;
  let amhPmolL = null;
  let amhObservationDate;

  // Convert height from cm to inches if necessary
  if (results["height"]) {
    const heightResult = results["height"];
    if (heightResult.observationUnits === "cm") {
      heightInches = heightResult.observationValue * 0.393701; // cm to inches
    } else {
      heightInches = heightResult.observationValue; // Assume it's already in inches
    }
  }

  // Convert weight from kg to pounds if necessary
  if (results["weight"]) {
    const weightResult = results["weight"];
    if (weightResult.observationUnits === "kg") {
      weightPounds = weightResult.observationValue * 2.20462; // kg to pounds
    } else {
      weightPounds = weightResult.observationValue; // Assume it's already in pounds
    }
  }

  // Convert AMH from ng/ml to pmol/L if necessary
  if (results["amh"]) {
    const amhResult = results["amh"];
    amhObservationDate = results["amh"].observationDate;
    if (amhResult.observationUnits === "ng/ml") {
      amhPmolL = amhResult.observationValue * 7.14; // Conversion factor
    } else {
      amhPmolL = amhResult.observationValue; // Assume it's already in pmol/L
    }
  }
  const amh = { amhValue: amhPmolL, amhObservationDate: amhObservationDate };

  return { height: heightInches, weight: weightPounds, amh };
};

export async function getPatientLabData(accessToken, patientId) {
  return getLabResults(accessToken, patientId)
    .then(labResults => {
      const labData = {};

      labResults.forEach(result => {
        result.observations.forEach(obs => {
          const labelLower = obs.label.toLowerCase();
          if (
            labelLower.includes('height') ||
            labelLower.includes('weight') ||
            labelLower.includes('amh') ||
            labelLower.includes('anti-mullerian')
          ) {
            if (labelLower.includes("amh") || labelLower.includes("anti-mullerian")) {
              console.log('found amh!', obs)
              // Handle merging AMH keys into one
              if (!labData["amh"] || new Date(labData["amh"].observationDate) < new Date(obs.observationDate)) {
                labData["amh"] = obs; // Always keep the latest AMH-related observation
              }
            } else {
  
              if (!labData[labelLower] ||new Date(labData[labelLower].observationDate) < new Date(obs.observationDate)) {
                labData[labelLower] = obs;
              }
            }
          }
        });
      });

      const amh = labData['amh'] || labData['anti-mullerian hormone'];
      const height = labData['height'];
      const weight = labData['weight'];

      const latestResults = {
        amh,
        height,
        weight,
      };

      const { height: heightInches, weight: weightPounds, amh: amhData } = convertLabResultsToStandardUnits(latestResults);

      const heightFeet = heightInches ? Math.floor(heightInches / 12) : '';
      const heightInchesRemainder = heightInches ? Math.round(heightInches % 12) : '';
      const roundedWeight = weightPounds ? Math.round(weightPounds,0) : '';
      const roundedAmh = !isEmpty(amhData?.amhValue) ? Math.ceil(amhData.amhValue) : '';
      return {
        amh: roundedAmh,
        amhObservationDate: amhData.amhObservationDate,
        heightFeet,
        heightInches: heightInchesRemainder,
        weight: roundedWeight,
      };
    })
    .catch(error => {
      console.error('Error in getPatientLabData:', error);
      throw new Error('Failed to get patient lab data');
    });
}


export async function getPatientLabAndDemographicData(patientId, microsoftAuthToken) {
  const accessToken = await getAccessToken(microsoftAuthToken);
  const demographics = await fetchPatientDemographics(accessToken, patientId);
  const {realPatientId, name} = demographics;
  const labData = await getPatientLabData(accessToken, realPatientId);

  return {labData, demographics}


  
}
