import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faHospital, faMoon, faP, faPhone, faSun, faVideo } from '@fortawesome/free-solid-svg-icons';
import CalendarEventPopover from '../common/InfoPopover';
import { useMediaQuery } from 'react-responsive';
import { isEmpty } from '../../js/common';

const getDayNumber = (phaseId, order) => {
    switch (phaseId){
        case 'phase-1-meds': 
            break;
        case 'phase-2-ovarian-stimulation': 
            return `D${order}`
        case 'phase-3-trigger-retrieval': 
            return `D${order + 8}` // 8 days of stim need to be appended here, as the egg retrieval is technically "stim until trigger date"
        case 'phase-4-recovery': 
            break;
        default: break;
    }
}

const ClinicalEventContent = ({ innerProps, printView }) => {
    const { title = '', extendedProps = {}, id, display} = innerProps.event;
    const { clinicVisit, cycleReview, nurseCall, icon, dayMeds, nightMeds, extraInfo=[] } = extendedProps;

    const printWidth = printView ? { width: 1025 } : undefined;
    const isDesktop = useMediaQuery({ query: `(min-width: 1025px)` }, printWidth);
    
    return (
        <div className="clinical-event-content">
            {icon && ( // Only render the phase icon if it exists and it's a desktop view
                <div className='icon-container'>
                    {isDesktop  && <span className="phase-icon">{icon} {getDayNumber(extendedProps.phaseId, extendedProps.order)}</span>}
                </div>
            )}
            {/* filling up the header space of recovery until we can find a good icon for it */}
            {(!icon && id.startsWith('phase-4-recovery')) && ( // Only render the phase icon if it exists and it's a desktop view
                <div className='icon-container'>
                    {isDesktop  && <span className="phase-icon"><FontAwesomeIcon className='invisible' icon={faCircle} size="lg"></FontAwesomeIcon> {getDayNumber(extendedProps.phaseId, extendedProps.order)}</span>}
                </div>
            )}

            {title && isDesktop && <span className="event-title">{title}</span>}
            {isDesktop ? ( // For desktop, show medication descriptions
                <div className="meds-description">
                    <div className='top-clinic-cell-panel'>
                        {dayMeds && <div className='AM-row'>{dayMeds}</div>}
                    </div>
                    <div className='merged-info-panel'>
                            {cycleReview && cycleReview.display!=='none' && <div className='info-item'><FontAwesomeIcon icon={faVideo} size={isDesktop ? 'md' : 'lg'} /> &nbsp;{cycleReview.extendedProps.description}</div>}
                            {clinicVisit && 
                                <div className='info-item'>
                                    {!isEmpty(clinicVisit.extendedProps.icon) && <><FontAwesomeIcon icon={faHospital} size={isDesktop ? 'md' : 'lg'} /> &nbsp;</>}
                                    {clinicVisit.extendedProps.description}
                                </div>
                            }
                            {nurseCall && <><div className='info-item'><FontAwesomeIcon icon={faPhone} size={isDesktop ? 'md' : 'lg'} /> &nbsp;{nurseCall.extendedProps.description}</div></>}
                            {!isEmpty(extraInfo) && extraInfo.map((info, index) => (
                                <div key={index} className='info-item'>
                                    <FontAwesomeIcon size='2xs' icon={faCircle} /> &nbsp;{info}
                                </div>
                            ))}
                    </div>

                    <div className='bottom-clinic-cell-panel'>
                        {nightMeds && <div className='PM-row'><span className='icon'></span> {nightMeds}</div>}
                    </div>

                </div>
            ) : ( // For mobile, allow each icon to have popover description
                <div className="meds-description">
                    {cycleReview && 
                        <span className="clinic-visit-icon">
                            <CalendarEventPopover
                                position="top"
                                title="Cycle Review"
                                body={<div dangerouslySetInnerHTML={{ __html: cycleReview.extendedProps.description }} />}
                                headerClassName={innerProps.event.classNames[0]}
                            >
                                <button className="btn event-btn-mobile">
                                    <span className='clickable-container'>
                                        <FontAwesomeIcon icon={faVideo} size={isDesktop ? 'md' : 'lg'} />
                                    </span>
                                </button>
                            </CalendarEventPopover>
                        </span>
                    } 
                    {clinicVisit && 
                        <span className="clinic-visit-icon">
                            <CalendarEventPopover
                                position="top"
                                title="Clinic Visit"
                                body={<div dangerouslySetInnerHTML={{ __html: !isEmpty(clinicVisit.extendedProps.description) ? clinicVisit.extendedProps.description : 'Clinic Visit'  }} />}
                                headerClassName={innerProps.event.classNames[0]}
                            >
                                <button className="btn event-btn-mobile">
                                    <span className='clickable-container'>
                                        <FontAwesomeIcon icon={faHospital} size={isDesktop ? 'md' : 'lg'} />
                                    </span>
                                </button>
                            </CalendarEventPopover>
                        </span>
                    } 
                    {nurseCall && 
                        <span className="clinic-visit-icon">
                            <CalendarEventPopover
                                position="top"
                                title="Nurse Call"
                                body={<div dangerouslySetInnerHTML={{ __html: !isEmpty(nurseCall.extendedProps.description) ? nurseCall.extendedProps.description : 'Nurse Call'  }} />}
                                headerClassName={innerProps.event.classNames[0]}
                            >
                                <button className="btn event-btn-mobile">
                                    <span className='clickable-container'>
                                        <FontAwesomeIcon icon={faPhone} size={isDesktop ? 'md' : 'lg'} />
                                    </span>
                                </button>
                            </CalendarEventPopover>
                        </span>
                    } 
                    {dayMeds && (
                        <CalendarEventPopover
                            position="top"
                            title="Day Medications"
                            body={<div>🌞 {dayMeds}</div>}
                            headerClassName={innerProps.event.classNames[0]}
                        >
                            <button className="btn meds-btn-mobile">
                                <span className='clickable-container'>
                                    <span className="meds-info">🌞</span>
                                </span>
                            </button>
                        </CalendarEventPopover>
                    )}
                    {nightMeds && (
                        <CalendarEventPopover
                            position="top"
                            title="Night Medications"
                            body={<div>🌙 {nightMeds}</div>}
                            headerClassName={innerProps.event.classNames[0]}
                        >
                            <button className="btn meds-btn-mobile">
                                <span className="clickable-container">
                                    <span className="meds-info">🌙</span>
                                </span>
                            </button>
                        </CalendarEventPopover>
                    )}
                </div>
            )}
        </div>
    );
};

export default ClinicalEventContent;
