import axios from "axios";
import { isEmpty } from "../common";

const VENNA_MIDDLEWARE_BASE_URL = process.env.REACT_APP_VENNA_MIDDLEWARE_BASE_URL;
const VENNAPLAN_API_BASE_URL = process.env.REACT_APP_VENNAPLAN_API_BASE_URL;


async function generateVennaPlan({amh, weight, takingAntibiotics,  existingPatientMedications, heightFt=5, heightIn=2}) {
    const payload = {
        plan_type: 'Egg Freezing',
        amh: amh,
        amh_unit: "pmol/L",
        weight: weight,
        weight_unit: "imperial system (ft/in and lbs)",
        height_ft: heightFt,
        height_in: heightIn,
        height_unit: "imperial system (ft/in and lbs)",
        age: 26,
        cycle_funding: 'Private',
        meds_taken: existingPatientMedications,
      };
    if (takingAntibiotics) payload.peri_retrieval = "Amoxiclav protocol"

    const headers = {'Content-Type': 'application/json'};
    return await axios.post(
        `${VENNAPLAN_API_BASE_URL}/vennaplan/generate_plan/`, payload,  { headers })
}

export {
    generateVennaPlan
};

// 0. mock up Input
// 1. send api request
// 2. team ivf ineligible
// 3. review medicatons json


// 4. parse meds json
    // 4.5 review dynamic dosages
    // some calendar meds need review
    // maybe only desc gets doses?
// 5. add height/weight inputs forbmi nnot to fail (optional for now...)
// 6. flag unused meds (sentry.io?)
// 7. mismatching meds? ie calendar has an extra med. see how to exclude? 




