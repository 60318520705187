import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment); // wrap moment with a date range library (for handling blackout dates)

const getWeeksBetweenDates = (formattedStart, formattedEnd) => {
  // Create moment objects for start and end
  const start = moment(formattedStart);
  const end = moment(formattedEnd).clone().subtract(1, 'days');  // FullCalendar treats end date as exclusive, so subtract 1 day

  // Calculate the number of full weeks between start and end, accounting for partial weeks
  const startOfWeek = start.clone().startOf('week');
  const endOfWeek = end.clone().endOf('week');

  // Calculate the number of weeks, including partial weeks
  const totalWeeks = Math.ceil(endOfWeek.diff(startOfWeek, 'days') / 7);

  return totalWeeks;
};

const getArrayIdFromObject = (arr, key, value) => {
  // console.log(arr, key, value)
    return arr.findIndex(p => p[key] === value); 
}

const isWeekend = (momentDate) => {
  let dayOfWeek = moment(momentDate).day();
  return (dayOfWeek === 6) || (dayOfWeek  === 0); // 6 = Saturday, 0 = Sunday
}

const isMonday = (momentDate) => {
  let dayOfWeek = moment(momentDate).day();
  return dayOfWeek === 1; // 1 = Monday
}
const isFriday = (momentDate) => {
  let dayOfWeek = moment(momentDate).day();
  return dayOfWeek === 5; // 1 = Fri
}

// if we are on Sunday, return the day after. Otherwise, return next week's monday.
const getNextMonday = (momentDate) => {
  let dayOfWeek = moment(momentDate).day();
  if (dayOfWeek  === 0) { // Sunday
    return momentDate.clone().weekday(1);
  } else {
    return momentDate.clone().weekday(8);
  }
}

const isEmpty = (val) => {
if (val === undefined)
  return true;
if (typeof (val) == 'function' || typeof (val) == 'number' || typeof (val) == 'boolean' || Object.prototype.toString.call(val) === '[object Date]')
  return false;
if (val == null || val.length === 0)        // null or 0 length array
  return true;
if (typeof (val) == "object") {
  // empty object
  var r = true;
  for (var f in val)
      r = false;
  return r;
}

return false;
}


const areDatesEqual = (firstDate, secondDate) => {
  return moment(firstDate).isSame(moment(secondDate));
}

const isDateInNext6Months = (date) => {
  return moment().diff(date, "days") > -180 && 
          moment().diff(date, "days") < 0;
}

const getThreeDaysAgo = () => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() - 3));
}
const getDateAtMorning = (startDate) => {
  const date = new Date(startDate);
  date.setHours(8, 0, 0, 0); // Set the time to 8:00 AM
  return date;
}

const cascadeEventList = (originalEvents, proposedEditedEvent) => {
  console.log('cascading events after', proposedEditedEvent.id);
  const newEventsList = [...originalEvents];
  const originalEventIndex = getArrayIdFromObject(originalEvents, 'id', proposedEditedEvent.id);
  const originalEvent = originalEvents[originalEventIndex];
  let updatedCascadeEventList = [];

  //daysToCascade may be either negative or positive
  const daysToCascade = moment.duration(moment(proposedEditedEvent.end).diff(moment(originalEvent.end))).asDays();

  // Saved end dates are exclusive in FullCalendar, so they have been previously padded by 1 day ahead. 
  // We have to undo this for proposedEndDateRange, as we check for overlaps with events that we want to cascade
  const proposedEndDateRange = moment.range(moment(proposedEditedEvent.end).clone().subtract(1,'day'), moment(originalEvent.end).clone().subtract(1, 'day'));
  // console.log(daysToCascade, 'days to cascade');
  
  originalEvents.forEach((eventToCascade, index) => {
    const eventToCascadeClone = {...eventToCascade};
    const eventDateRange = moment.range(eventToCascadeClone.start, eventToCascadeClone.end || eventToCascadeClone.start);

    const isFutureEvent = moment(eventToCascade.end || eventToCascade.start).isAfter(moment(originalEvent.end));
    // To cascade: all future events, or events that overlap within the range of change for end dates (proposedEndDateRange)
    if (isFutureEvent || proposedEndDateRange.overlaps(eventDateRange, {adjacent: true})) {
      eventToCascadeClone.start = moment(eventToCascade.start).clone().add(daysToCascade, 'day').format('YYYY-MM-DD');
      if (!isEmpty(newEventsList[index].end)) {
        eventToCascadeClone.end = moment(eventToCascade.end).clone().add(daysToCascade, 'day').format('YYYY-MM-DD');
      }
      // console.log('shifted', eventToCascade.id, 'from', eventToCascade.start, 'to', moment(eventToCascade.start).clone().add(daysToCascade, 'day').format('YYYY-MM-DD'));
      updatedCascadeEventList.push(eventToCascadeClone);
    }
  });
  return updatedCascadeEventList;
}

const getDateAfterDays = (startDate, days) => {
  return moment(startDate).clone().add(days, 'days').format('YYYY-MM-DD');
}
const convertLbsToKg = (weightInLbs) => {
  const result = weightInLbs / 2.2046226218;
  return Math.round(result * 100) / 100; // Round to two decimal places
}

const convertKgToLbs = (weightInKg) => {
  const result = weightInKg * 2.2046226218;
  return Math.round(result * 100) / 100; // Round to two decimal places
}

const convertArrayToObj = (arr, discriminator) => {
  return arr.reduce((obj, cur) => ({...obj, [cur[discriminator]]: cur}), {});
}


const regenerateEventsWithStimOffset = (eventList, eventToUpdate, stimOffset) => {

  let newEventsObject = convertArrayToObj(eventList, 'id');
  const originalEvent = newEventsObject[eventToUpdate.id]

  // deep clone event so we don't modify the originalEvents object, since JS arrays are mutable
  const eventToUpdateClone = {
    ...newEventsObject[eventToUpdate.id],
    start: originalEvent.start,
    end: originalEvent.end,
    extendedProps: {...originalEvent.extendedProps}
  };

  if (stimOffset > 0){
    eventToUpdateClone.end = moment(originalEvent.end).clone().add(stimOffset, 'days').format('YYYY-MM-DD');

    // additionally update cycle orientation, as it is the only event that overlaps phase 1 that needs to also be pushed to future
    let cycleOrientationEvent = newEventsObject['cycle-orientation']
    cycleOrientationEvent = {
      ...newEventsObject['cycle-orientation'],
      start: moment(cycleOrientationEvent.start).add(stimOffset, 'days').format('YYYY-MM-DD'),
      end: moment(cycleOrientationEvent.end).add(stimOffset, 'days').format('YYYY-MM-DD'),
      extendedProps: {...cycleOrientationEvent.extendedProps}
    };    
    // cascade all future non-overlapping events
    const cascadedEvents = cascadeEventList(eventList, eventToUpdateClone);
    return Object.values({...newEventsObject, ...convertArrayToObj(cascadedEvents, 'id'), eventToUpdateClone, cycleOrientationEvent}); // order the spread so that later events override former ones

  }  else if (stimOffset < 0) {
    eventToUpdateClone.end = moment(originalEvent.end).clone().subtract(Math.abs(stimOffset), 'days').format('YYYY-MM-DD');
    const cascadedEvents = cascadeEventList(eventList, eventToUpdateClone);
    return Object.values({...newEventsObject, ...convertArrayToObj(cascadedEvents, 'id'), eventToUpdateClone}); // order the spread so that later events override former ones
  }
}

function getDefaultMonitoringAppointments(events, stimStartDay){
  if (isEmpty(events) || isEmpty(stimStartDay)){
    return {}
  } else {
    const stimulationStart = events.filter(event => event.id.includes('phase-2-ovarian-stimulation'))[0].start;
    let monitoringAppointments;
    switch (stimStartDay.stimStartDayOfWeek){
      case 'Monday':
        monitoringAppointments = {
          // day 1,5,8,9
          [`clinic-visit-monitoring-1`]: {date: moment(getDateAfterDays(stimulationStart, 1 - 1)).toDate()} , 
          [`clinic-visit-monitoring-2`]: {date: moment(getDateAfterDays(stimulationStart, 5 - 1)).toDate()} ,
          [ `clinic-visit-monitoring-3`]: {date: moment(getDateAfterDays(stimulationStart, 8 - 1)).toDate()} ,
          [ `clinic-visit-monitoring-4`]: {date: moment(getDateAfterDays(stimulationStart, 9 - 1)).toDate()},
        }
        break;
      case 'Saturday':
        // day 0,5,7,9
        monitoringAppointments = {
          [`clinic-visit-monitoring-1`]: {date: moment(getDateAfterDays(stimulationStart, 0 - 1)).toDate()} ,
          [`clinic-visit-monitoring-2`]: {date: moment(getDateAfterDays(stimulationStart, 5 - 1)).toDate()} ,
          [`clinic-visit-monitoring-3`]: {date: moment(getDateAfterDays(stimulationStart, 7 - 1)).toDate()} ,
          [`clinic-visit-monitoring-4`]: {date: moment(getDateAfterDays(stimulationStart, 9 - 1)).toDate()},
        }
        break;
      case 'Sunday':
        // day -1,4,6,9  
        monitoringAppointments = {
          [`clinic-visit-monitoring-1`]: {date: moment(getDateAfterDays(stimulationStart, -1 - 1)).toDate()} ,
          [`clinic-visit-monitoring-2`]: {date: moment(getDateAfterDays(stimulationStart, 4 - 1)).toDate()} ,
          [`clinic-visit-monitoring-3`]: {date: moment(getDateAfterDays(stimulationStart, 6 - 1)).toDate()} ,
          [`clinic-visit-monitoring-4`]: {date: moment(getDateAfterDays(stimulationStart, 9 - 1)).toDate()},
        }
        break;
      default: break;
      }
      return monitoringAppointments;
    }

}

function getProtocolFromAMH(amh) {
  if (amh < 12) {
    return 1;
  } else if (amh >= 12 && amh < 24) {
    return 2;
  } else if (amh >= 24) {
    return 3;
  }
}

export {
    getWeeksBetweenDates,
    getArrayIdFromObject,
    isWeekend,
    isMonday,
    isFriday,
    getNextMonday,
    isEmpty,
    areDatesEqual,
    isDateInNext6Months,
    getThreeDaysAgo,
    cascadeEventList,
    getDateAfterDays,
    convertLbsToKg,
    convertKgToLbs,
    getDateAtMorning,
    regenerateEventsWithStimOffset,
    getDefaultMonitoringAppointments,
    getProtocolFromAMH
}



